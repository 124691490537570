import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Headline = styled.h6`
  margin-bottom: ${spacing(1)}px;
  font-weight: 600;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing(1)}px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;

import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const TicketPrice = styled.p`
  font-size: ${pxToRem(14)};
  font-style: italic;
  color: rgba(18, 50, 102, 0.6);
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin: ${spacing(1)}px 0;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(20)};
  line-height: 1;
`;

export const Description = styled.p`
  font-size: ${pxToRem(14)};
`;

import {
  handleError,
  MessageStatus,
  useMessages,
  useUpdateMessage,
} from "@hotelspoint/api";
import {
  MessagesPriorityDialog,
  MessagesReadDialog,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import getColumns from "./AdminMessages.columns";
import AdminMessagesFilters from "./AdminMessagesFilters";
import useAdminMessagesParams from "./useAdminMessagesParams";

const AdminMessages = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminMessages.pageTitle"));

  const navigate = useNavigate();

  const [isReadDialogOpen, setIsReadDialogOpen] = useState(false);
  const [isPrirityDialogOpen, setIsPrirityDialogOpen] = useState(false);
  const [targetId, setTargetId] = useState<number | null>(null);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminMessagesParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);
  const [messages, isLoading] = useMessages({
    pageIndex,
    pageSize,
    ...filters,
  });

  const [updateMessage, isUpdating] = useUpdateMessage();

  const data = useMemo(() => messages?.results ?? [], [messages]);

  const handleOpenReadModal = (id: number) => {
    setTargetId(id);
    setIsReadDialogOpen(true);
  };

  const handleOpenPriorityModal = (id: number) => {
    setTargetId(id);
    setIsPrirityDialogOpen(true);
  };

  const handleUpdateStatus = useCallback(
    async (id: number, status: MessageStatus | null) => {
      try {
        setTargetId(id);

        await updateMessage({
          id,
          payload: {
            status,
          },
        });

        toast.success(t("toast.messages.updateStatus"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, updateMessage],
  );

  const handleUpdateAlert = useCallback(
    async (id: number, value: boolean) => {
      try {
        setTargetId(id);

        await updateMessage({
          id,
          payload: {
            isAlert: value,
          },
        });

        toast.success(t("toast.messages.updateAlert"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, updateMessage],
  );

  const columns = useMemo(() => {
    return getColumns({
      t,
      targetId,
      isUpdating,
      navigate,
      handleOpenReadModal,
      handleOpenPriorityModal,
      handleUpdateStatus,
      handleUpdateAlert,
    });
  }, [
    t,
    targetId,
    isUpdating,
    navigate,
    handleUpdateStatus,
    handleUpdateAlert,
  ]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <MessagesReadDialog
        id={targetId as number}
        isOpen={isReadDialogOpen}
        setIsOpen={setIsReadDialogOpen}
      />
      <MessagesPriorityDialog
        id={targetId as number}
        isOpen={isPrirityDialogOpen}
        setIsOpen={setIsPrirityDialogOpen}
      />
      <Panel title={t("adminMessages.pageTitle")}>
        <AdminMessagesFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
                columnPinning: { left: ["id"], right: ["actions"] },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={messages?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={messages?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default AdminMessages;

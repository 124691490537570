import { ActivityContent as ActivityContentAPI } from "@hotelspoint/api";
import theme from "@hotelspoint/theme";
import { ActivityContentMark } from "@hotelspoint/types";
import { IconCircleCheck, IconForbid } from "@tabler/icons-react";
import DOMPurify from "dompurify";
import Skeleton from "react-loading-skeleton";

import * as S from "./ActivityContent.styled";

interface ActivityContentProps {
  content?: ActivityContentAPI[];
  isLoading?: boolean;
}

const getIcon = (mark?: string) => {
  switch (mark) {
    case ActivityContentMark.Included:
      return (
        <IconCircleCheck
          size={18}
          style={{ color: theme.palette.eucalyptus }}
        />
      );
    case ActivityContentMark.Excluded:
      return <IconForbid size={18} style={{ color: theme.palette.red }} />;
    default:
      return null;
  }
};

const ActivityContent = ({ content, isLoading }: ActivityContentProps) => {
  if (isLoading)
    return (
      <S.SkeletonWrapper>
        <Skeleton height={20} />
        <Skeleton count={4} height={16} />
      </S.SkeletonWrapper>
    );

  return (
    <>
      {content?.map(item => (
        <div key={item.name}>
          <S.Headline>{item.name}</S.Headline>
          {item.mark ? (
            <S.List>
              {item.text.map((text, textIndex) => (
                <S.ListItem key={textIndex}>
                  {getIcon(item.mark)}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(text),
                    }}
                  />
                </S.ListItem>
              ))}
            </S.List>
          ) : (
            item.text.map((text, textKey) => (
              <span
                key={textKey}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
              />
            ))
          )}
          <S.Divider />
        </div>
      ))}
    </>
  );
};

export default ActivityContent;

import { useLogout } from "@hotelspoint/api";
import {
  useIsUserRole,
  useResetUserStores,
  useUserCurrencyStore,
  useUserLocaleStore,
} from "@hotelspoint/store";
import { UserRole } from "@hotelspoint/types";
import {
  IconCash,
  IconLogout,
  IconSettings,
  IconWorld,
} from "@tabler/icons-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { MenuDivider, MenuItem, SubMenu } from "../../../composites/Menu";
import NavLink from "../../../misc/NavLink";
import currencies from "../currencies";
import HeaderMenu from "../HeaderMenu";
import languages from "../languages";
import profileNavItems, { type ProfileNavItem } from "../profileNavItems";
import UserCurrentOffer from "../UserCurrentOffer";
import UserWelcome from "../UserWelcome";
import * as S from "./UserProfile.styled";

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [logoutUser] = useLogout();

  const isSuperAdmin = useIsUserRole(UserRole.SuperAdministrator);

  const { language, set: setLanguage } = useUserLocaleStore(state => state);
  const { currency, set: setCurrency } = useUserCurrencyStore(state => state);

  const resetUserStores = useResetUserStores();

  const handleLogout = useCallback(async () => {
    try {
      await logoutUser();
    } catch (error) {
      resetUserStores();
    } finally {
      navigate("/");
      toast.success(t("toast.logout"));
    }
  }, [t, logoutUser, resetUserStores, navigate]);

  const renderProfileItem = (item: ProfileNavItem) => (
    <NavLink key={item.href} to={item.href}>
      <MenuItem>
        <S.MenuItem>
          <S.MenuItemHeader>
            <S.IconWrapper>
              {item.icon && <item.icon size={22} />}
            </S.IconWrapper>
            <S.Title>{t(item.title)}</S.Title>
          </S.MenuItemHeader>
          {typeof item.renderCounter === "function" && (
            <S.MenuItemStats>{item.renderCounter()}</S.MenuItemStats>
          )}
        </S.MenuItem>
      </MenuItem>
    </NavLink>
  );

  return (
    <S.Wrapper>
      <UserCurrentOffer />
      <HeaderMenu menuButton={<UserWelcome />}>
        <S.MenuItems>
          {profileNavItems.map(renderProfileItem)}
          <MenuDivider />
          <SubMenu
            gap={4}
            arrow
            label={
              <S.MenuItem>
                <S.MenuItemHeader>
                  <S.IconWrapper>
                    <IconWorld size={22} />
                  </S.IconWrapper>
                  <S.Title>{t("header.userLocale")}</S.Title>
                </S.MenuItemHeader>
              </S.MenuItem>
            }
          >
            {languages.map(lang => (
              <MenuItem
                key={lang.value}
                disabled={lang.value === language}
                onClick={() => setLanguage(lang.value)}
              >
                <S.MenuItem>
                  <S.MenuItemHeader>
                    <S.FlagIcon src={lang.icon} alt={lang.title} />
                    <p>{lang.title}</p>
                  </S.MenuItemHeader>
                </S.MenuItem>
              </MenuItem>
            ))}
          </SubMenu>
          <SubMenu
            gap={4}
            arrow
            label={
              <S.MenuItem>
                <S.MenuItemHeader>
                  <S.IconWrapper>
                    <IconCash size={22} />
                  </S.IconWrapper>
                  <S.Title>{t("header.userCurrency.title")}</S.Title>
                </S.MenuItemHeader>
              </S.MenuItem>
            }
          >
            {currencies.map(curr => (
              <MenuItem
                key={curr.value}
                disabled={curr.value === currency}
                onClick={() => setCurrency(curr.value)}
              >
                <S.MenuItem>
                  <S.MenuItemHeader>
                    <span>{curr.value}</span>
                    <p>{t(curr.title)}</p>
                  </S.MenuItemHeader>
                </S.MenuItem>
              </MenuItem>
            ))}
          </SubMenu>
          {isSuperAdmin && (
            <>
              <MenuDivider />
              <NavLink to="/admin">
                <MenuItem>
                  <S.MenuItem>
                    <S.MenuItemHeader>
                      <S.IconWrapper>
                        <IconSettings size={22} />
                      </S.IconWrapper>
                      <S.Title>{t("admin.pageTitle")}</S.Title>
                    </S.MenuItemHeader>
                  </S.MenuItem>
                </MenuItem>
              </NavLink>
            </>
          )}
          <MenuDivider />
          <MenuItem onClick={handleLogout}>
            <S.MenuItem>
              <S.MenuItemHeader>
                <S.IconWrapper>
                  <IconLogout size={22} />
                </S.IconWrapper>
                <S.Title>{t("header.userProfile.logout")}</S.Title>
              </S.MenuItemHeader>
            </S.MenuItem>
          </MenuItem>
        </S.MenuItems>
      </HeaderMenu>
    </S.Wrapper>
  );
};

export default UserProfile;

import { ActivitySearchQuery } from "@hotelspoint/types";

import { FormValues } from "../../SearchActivitiesForm/SearchActivitiesForm.form";

export const entity2Form = (formValues: ActivitySearchQuery): FormValues => ({
  search: "",
  dates: {
    from: new Date(formValues.dateIn),
    to: new Date(formValues.dateOut),
  },
  travelers: {
    adults: formValues.adults,
    children: formValues.children,
  },
  place: {
    id: formValues.placeId,
    type: formValues.placeType,
  },
});

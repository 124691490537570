import {
  HotelSearchInfoResponse,
  useHotelDetails,
  useHotelRate,
  useHotelSearchInfo,
} from "@hotelspoint/api";
import {
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import { Hotel } from "@hotelspoint/types";
import { Navigate, useParams } from "react-router-dom";

import SearchHotelBookContent from "./SearchHotelBookContent";
import SearchHotelBookSidebar from "./SearchHotelBookSidebar";
import useSearchHotelBookParams from "./useSearchHotelBookParams";

interface SearchHotelBookParams extends Record<string, string> {
  id: string;
}

const SearchHotelBook = () => {
  const { id } = useParams<SearchHotelBookParams>();

  const hotelId = Number(id);
  const [{ searchId, rateId }] = useSearchHotelBookParams();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(hotelId);
  const [searchInfo, isLoadingSearchInfo] = useHotelSearchInfo(
    searchId as number,
  );

  const [hotelRate, isLoadingHotelRate] = useHotelRate(
    searchId as number,
    hotelId,
    rateId as number,
  );

  const isLoading =
    isLoadingHotelDetails || isLoadingSearchInfo || isLoadingHotelRate;

  if (!hotelId || !searchId || !rateId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <PageSplitLayout wide>
            <PageSplitLayout.Sidebar>
              <SearchHotelBookSidebar
                hotelData={hotelDetails as Hotel}
                searchData={searchInfo as HotelSearchInfoResponse}
                hotelRateData={hotelRate}
                isLoading={isLoading}
              />
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchHotelBookContent
                hotelRate={hotelRate}
                isLoading={isLoading}
              />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchHotelBook;

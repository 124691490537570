import { css } from "styled-components";
import { theme } from "styled-tools";

import { borderRadius } from "../theme";
import { pxToRem } from "../utils";

export default css`
  .ql-toolbar {
    display: flex;
    border-radius: ${borderRadius.normal}px ${borderRadius.normal}px 0 0;
  }

  .ql-container.ql-snow {
    border-top: none;
  }

  .ql-container {
    color: ${theme("palette.black")};
    font-size: ${pxToRem(14)};
    border-radius: 0 0 ${borderRadius.normal}px ${borderRadius.normal}px;
  }

  .ql-editor {
    min-height: 100px;
  }

  .ql-templates {
    min-width: 100px;

    .ql-picker-label {
      border-color: #ccc !important;
    }

    .ql-picker-label:before {
      content: "Template";
    }

    .ql-picker-item:before {
      content: attr(data-label);
    }
  }
`;

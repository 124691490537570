import { usePayment } from "@hotelspoint/api";
import {
  formatDateTime,
  getPaymentCreditStatusColor,
  getPaymentCreditStatusName,
  getPaymentStatusColor,
  getPaymentStatusName,
  getPaymentTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { Chip } from "../../elements";
import Button from "../../elements/Button";
import { Box, Flex } from "../../grid";
import DataItem from "../DataItem";
import Dialog from "../Dialog";
import Modal from "../Modal";

interface PaymentDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  id: number;
}

const PaymentDialog = ({ isOpen, setIsOpen, id }: PaymentDialogProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [payment, isLoadingPayment] = usePayment(id);

  const reservationUrl = useMemo(
    () => `/profile/bookings/${payment?.reservationId}`,
    [payment],
  );

  if (!payment || isLoadingPayment) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Modal
          title={t("components.paymentDialog.title")}
          onClose={() => setIsOpen(false)}
        >
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.id")}
                value={payment.id}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.reservationId")}
                value={payment.reservationId}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.type")}
                value={t(getPaymentTypeName(payment.type))}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.agencyName")}
                value={payment.agencyName}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.amount")}
                value={withCurrency(payment.amount, payment.currency)}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.dateCreated")}
                value={formatDateTime(payment.dateCreated)}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.status")}
                value={
                  <Chip $color={getPaymentStatusColor(payment.status)}>
                    {t(getPaymentStatusName(payment.status))}
                  </Chip>
                }
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <DataItem
                title={t("components.paymentDialog.columns.creditStatus")}
                value={
                  payment.creditStatus && (
                    <Chip
                      $color={getPaymentCreditStatusColor(payment.creditStatus)}
                    >
                      {t(getPaymentCreditStatusName(payment.creditStatus))}
                    </Chip>
                  )
                }
              />
            </Box>
          </Flex>
          <Modal.Actions>
            <Button variant="outlined" onClick={() => setIsOpen(false)}>
              {t("components.paymentDialog.actions.close")}
            </Button>
            {pathname !== reservationUrl && (
              <Link to={reservationUrl}>
                <Button variant="primary">
                  {t("components.paymentDialog.actions.viewBooking")}
                </Button>
              </Link>
            )}
          </Modal.Actions>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default PaymentDialog;

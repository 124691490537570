import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  row-gap: ${spacing(2)}px;

  @media ${mediaQuery.tablet} {
    min-height: 400px;
  }
`;

export const Content = styled.div`
  text-align: center;
`;

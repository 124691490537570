import { handleError, useUploadDocuments } from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormContext,
  FormDropZone,
  FormLayout,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { createFormData } from "@hotelspoint/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./RegisterConfirmForm.form";
import * as S from "./RegisterConfirmForm.styled";

interface RegisterConfirmFormProps {
  token?: string | null;
}

const RegisterConfirmForm = ({ token }: RegisterConfirmFormProps) => {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadDocuments, isUploadingDocuments] = useUploadDocuments();

  const onSubmit = async (values: FormValues) => {
    const formData = createFormData(values.files as File[]);

    try {
      const response = await uploadDocuments({
        payload: formData,
        params: {
          token: token as string,
        },
      });

      if (response.result) {
        setIsSuccess(true);
      } else {
        throw new Error(t("toast.unhandled"));
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {isSuccess ? (
        <StatusBlock status={StatusFlavour.Success}>
          <h4>{t("registerConfirm.success.title")}</h4>
          <p>{t("registerConfirm.success.description")}</p>
        </StatusBlock>
      ) : (
        <>
          {!token && (
            <S.StatusWrapper>
              <StatusBlock status={StatusFlavour.Error}>
                {t("registerConfirm.errors.invalidToken")}
              </StatusBlock>
            </S.StatusWrapper>
          )}
          <FormLayout>
            <h4>{t("registerConfirm.title")}</h4>
            <div>
              <FormAdapter
                name="files"
                label={t("registerConfirm.files.label")}
              >
                {props => (
                  <FormDropZone
                    {...props}
                    placeholder={t("registerConfirm.files.placeholder")}
                    disabled={!token}
                  />
                )}
              </FormAdapter>
            </div>
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button
                  type="submit"
                  variant="secondary"
                  isLoading={isUploadingDocuments}
                  isDisabled={!token}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("registerConfirm.submit")}
                </Button>
              )}
            />
          </FormLayout>
        </>
      )}
    </Form>
  );
};

export default RegisterConfirmForm;

import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

import EmptyArea from "../../elements/EmptyArea";

export const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 8px 8px 0 0;

  thead {
    tr {
      th {
        background-color: ${theme("palette.bahamaBlue")};
        text-align: left;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: ${pxToRem(12)};
        line-height: 1;
        font-weight: 700;
        color: ${theme("palette.white")};
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: ${theme("palette.white")};
      }
      &:nth-child(even) {
        td {
          background-color: ${theme("palette.whiteSand")};
        }
      }
    }
  }

  td,
  th {
    padding: ${spacing(1)}px ${spacing(2)}px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    &:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
`;

export const EmptyState = styled(EmptyArea)`
  flex-direction: column;
  row-gap: ${spacing(1)}px;
  margin-top: ${spacing(0.5)}px;
  padding: ${spacing(4)}px 0;
`;

export const EmptyStateTitle = styled.h6`
  color: ${theme("palette.blueBayoux")};
`;

export const EmptyStateDescription = styled.p`
  color: ${theme("palette.doveGrey")};
`;

import { HotelRoomGuests } from "@hotelspoint/types";
import { IconChevronDown, IconUsers } from "@tabler/icons-react";
import range from "lodash/range";
import sumBy from "lodash/sumBy";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Popover from "../../composites/Popover";
import Button from "../../elements/Button";
import { Box, Flex } from "../../grid";
import { type FormControlInjectedProps } from "../FormControl";
import FormCounter from "../FormCounter";
import FormInput from "../FormInput";
import FormLabel from "../FormLabel";
import FormSelect from "../FormSelect";
import * as S from "./FormRoomSelector.styled";

const DEFAULT_CONFIG: FormRoomSelectorConfig = {
  maxAdults: 12,
  maxChildren: 12,
  maxChildrenAge: 13,
};

interface FormRoomSelectorConfig {
  maxAdults: number;
  maxChildren: number;
  maxChildrenAge: number;
}

interface FormRoomSelectorProps
  extends FormControlInjectedProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "placeholder"> {
  value: HotelRoomGuests[];
  onChange: (rooms: HotelRoomGuests[]) => void;
  config?: FormRoomSelectorConfig;
}

export const FORM_ROOM_SELECTOR_INITIAL_STATE = {
  adults: 2,
  children: [],
};

const FormRoomSelector = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  config = DEFAULT_CONFIG,
}: FormRoomSelectorProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState<HotelRoomGuests[]>(value);

  const handleRoomsChange = useCallback(
    (rooms: number) => {
      if (rooms > localValue.length) {
        setLocalValue([...localValue, FORM_ROOM_SELECTOR_INITIAL_STATE]);
      } else {
        setLocalValue(localValue.slice(0, rooms));
      }
    },
    [localValue],
  );

  const handleAdultsChange = useCallback(
    (adults: number, index: number) => {
      setLocalValue(
        localValue.map((room, roomIndex) => {
          if (roomIndex === index) {
            return { ...room, adults };
          }

          return room;
        }),
      );
    },
    [localValue],
  );

  const handleChildrenChange = useCallback(
    (children: number, index: number) => {
      setLocalValue(
        localValue.map((room, roomIndex) => {
          if (roomIndex === index) {
            if (children > room.children.length) {
              return { ...room, children: [...room.children, 0] };
            } else {
              return { ...room, children: room.children.slice(0, children) };
            }
          }

          return room;
        }),
      );
    },
    [localValue],
  );

  const handleChildrenAgeChange = useCallback(
    (childrenAge: number, index: number, childrenIndex: number) => {
      setLocalValue(
        localValue.map((room, roomIndex) => {
          if (roomIndex === index) {
            return {
              ...room,
              children: room.children.map((child, childIndex) => {
                if (childIndex === childrenIndex) {
                  return childrenAge;
                }

                return child;
              }),
            };
          }

          return room;
        }),
      );
    },
    [localValue],
  );

  const displayValue = useMemo(() => {
    if (value.length === 0) return undefined;

    const totalAdults = sumBy(value, "adults");
    const totalChildren = sumBy(value, "children.length");

    const roomCountText = t("components.formRoomSelector.roomCount", {
      count: value.length,
    });

    const adultsText =
      totalAdults !== 0
        ? t("components.common.adultCount", { count: totalAdults })
        : "";

    const childrenText =
      totalChildren !== 0
        ? t("components.common.childCount", { count: totalChildren })
        : "";

    return [roomCountText, adultsText, childrenText]
      .filter(str => str)
      .join(", ");
  }, [t, value]);

  const handleConfirm = () => {
    setIsOpen(false);
    onChange(localValue);
  };

  const childAgeOptions = useMemo(() => {
    // Range is not including the end, so +1 is needed
    return range(1, config.maxChildrenAge + 1).map(value => ({
      label: `${value}`,
      value,
    }));
  }, [config.maxChildrenAge]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-start">
      <Popover.Trigger onClick={() => setIsOpen(true)}>
        <FormInput
          id={id}
          name={name}
          value={displayValue}
          placeholder={placeholder}
          onChange={() => {}}
          startAdornment={<IconUsers size={18} />}
          endAdornment={
            <IconChevronDown
              size={18}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          }
          style={{ pointerEvents: "none" }}
        />
      </Popover.Trigger>
      <Popover.Content>
        <S.PaperWrapper>
          <S.FlexWrapper>
            <FormLabel id="rooms" htmlFor="rooms">
              {t("components.formRoomSelector.rooms")}
            </FormLabel>
            <FormCounter
              id="rooms"
              name="rooms"
              value={localValue.length}
              onChange={handleRoomsChange}
            />
          </S.FlexWrapper>
          {localValue.length !== 0 && <S.Divider />}
          {localValue.map((room, roomIndex) => {
            return (
              <S.RoomWrapper key={roomIndex}>
                <Flex>
                  <Box width={[1]}>
                    <S.RoomHeader>
                      {t("components.formRoomSelector.room", {
                        value: roomIndex + 1,
                      })}
                    </S.RoomHeader>
                  </Box>
                </Flex>
                <S.FlexWrapper>
                  <FormLabel
                    id={`room-${roomIndex}-adults`}
                    htmlFor={`room-${roomIndex}-adults`}
                  >
                    {t("components.formRoomSelector.adults")}
                  </FormLabel>
                  <FormCounter
                    id={`room-${roomIndex}-adults`}
                    name={`room-${roomIndex}-adults`}
                    value={room.adults}
                    onChange={localValue =>
                      handleAdultsChange(localValue, roomIndex)
                    }
                    disabled={{
                      increment: room.adults >= config.maxAdults,
                    }}
                  />
                </S.FlexWrapper>
                <S.FlexWrapper>
                  <FormLabel
                    id={`room-${roomIndex}-children`}
                    htmlFor={`room-${roomIndex}-children`}
                  >
                    {t("components.formRoomSelector.children")}
                  </FormLabel>
                  <FormCounter
                    id={`room-${roomIndex}-children`}
                    name={`room-${roomIndex}-children`}
                    value={room.children.length}
                    onChange={localValue =>
                      handleChildrenChange(localValue, roomIndex)
                    }
                    disabled={{
                      increment: room.children.length >= config.maxChildren,
                    }}
                  />
                </S.FlexWrapper>
                <Flex marginX={-1} style={{ alignItems: "baseline" }}>
                  {room.children.map((child, childrenIndex) => {
                    return (
                      <Fragment key={childrenIndex}>
                        {childrenIndex === 0 && (
                          <Box width={[1]} paddingX={1} paddingY={1}>
                            <FormLabel
                              id={`children-${roomIndex}-${childrenIndex}-age`}
                              htmlFor={`children-${roomIndex}-${childrenIndex}-age`}
                            >
                              {t("components.formRoomSelector.childAges.label")}
                            </FormLabel>
                          </Box>
                        )}
                        <Box
                          width={[1 / 2]}
                          paddingX={1}
                          paddingTop={childrenIndex > 1 ? 2 : 0}
                        >
                          <FormSelect
                            id={`children-${roomIndex}-${childrenIndex}-age`}
                            name={`children-${roomIndex}-${childrenIndex}-age`}
                            value={child}
                            placeholder={t(
                              "components.formRoomSelector.childAges.placeholder",
                            )}
                            options={childAgeOptions}
                            onChange={localValue =>
                              handleChildrenAgeChange(
                                localValue,
                                roomIndex,
                                childrenIndex,
                              )
                            }
                          />
                        </Box>
                      </Fragment>
                    );
                  })}
                </Flex>
                {roomIndex !== localValue.length - 1 && <S.Divider />}
              </S.RoomWrapper>
            );
          })}
          <S.ActionWrapper>
            <Button
              variant="tertiary"
              onClick={handleConfirm}
              isDisabled={localValue.length === 0}
            >
              {t("components.formRoomSelector.apply")}
            </Button>
          </S.ActionWrapper>
        </S.PaperWrapper>
      </Popover.Content>
    </Popover>
  );
};

export default FormRoomSelector;

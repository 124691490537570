import { utc } from "@date-fns/utc";
import {
  addDays as addDaysFn,
  differenceInDays,
  eachDayOfInterval as eachDayOfIntervalFn,
  endOfWeek as endOfWeekFn,
  format,
  isBefore,
  isSameDay as isSameDayFn,
  isToday,
  lastDayOfMonth,
  set,
  startOfWeek as startOfWeekFn,
  subDays as subDaysFn,
} from "date-fns";

import { formatDateTimeIso } from "./format";

export type DateType = Date | number | string;

export interface DateRange {
  from: DateType;
  to: DateType;
}

export const daysBetween = ({ from, to }: DateRange) => {
  return differenceInDays(to, from);
};

export const isDateToday = isToday;

export const isDateBefore = isBefore;

export const isSameDay = isSameDayFn;

export const addDays = addDaysFn;

export const subDays = subDaysFn;

export const getFirstDayOfMonth = (date: DateType) => {
  return format(date, "yyyy-MM-01");
};

export const getLastDayOfMonth = lastDayOfMonth;

export const getPreviousMonthDate = (date: Date) => {
  const currentDate = new Date(date);
  const previousMonth = new Date(currentDate.getTime());

  previousMonth.setDate(0);

  return previousMonth;
};

export const eachDayOfInterval = eachDayOfIntervalFn;

export const endOfWeek = endOfWeekFn;

export const startOfWeek = startOfWeekFn;

export const setDateValues = set;

export const setTimeInUtc = (date: DateType, time: string) => {
  if (!date || !time) return "";

  const [hours, minutes] = time.split(":");

  return formatDateTimeIso(
    setDateValues(
      date,
      {
        hours: Number(hours),
        minutes: Number(minutes),
      },
      {
        in: utc,
      },
    ),
  );
};

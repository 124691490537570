import styled from "styled-components";
import { prop } from "styled-tools";

import BaseChip from "../BaseChip";

export default styled(BaseChip)<{ $color?: string; $background?: string }>`
  color: ${prop("$color")};
  background: white;
  border-color: ${prop("$color")};
  font-weight: 500;
`;

import { PlaceType } from "@hotelspoint/types";
import { formatDate, isDateBefore } from "@hotelspoint/utils";
import { IconCalendar, IconUsers } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PlaceTypeIcon from "../PlaceTypeIcon";
import Tooltip from "../Tooltip";
import * as S from "./HotelSearchCard.styled";

interface HotelSearchCardProps {
  type: PlaceType;
  name: string;
  checkIn: string;
  checkOut: string;
  adults: number;
  children: number;
}

const HotelSearchCard = ({
  type,
  name,
  checkIn,
  checkOut,
  adults,
  children,
}: HotelSearchCardProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const isCheckInOutdated = useMemo(
    () => isDateBefore(new Date(checkIn), dateToday),
    [checkIn, dateToday],
  );

  const dateRange = useMemo(() => {
    return [checkIn, checkOut].map(value => formatDate(value)).join(" \u2014 ");
  }, [checkIn, checkOut]);

  const adultsDisplay = t("components.common.adultCount", {
    count: adults,
  });

  const childrenDisplay = t("components.common.childCount", {
    count: children,
  });

  return (
    <S.Wrapper>
      <S.Detail>
        <PlaceTypeIcon type={type} iconProps={{ size: 20 }} />
        <S.Title title={name}>{name}</S.Title>
      </S.Detail>
      <S.Detail>
        <IconCalendar size={20} />
        {isCheckInOutdated ? (
          <Tooltip placement="right">
            <Tooltip.Trigger>
              <s>{dateRange}</s>
            </Tooltip.Trigger>
            <Tooltip.Content>
              {t("components.hotelSearchCard.checkInOutdated")}
            </Tooltip.Content>
          </Tooltip>
        ) : (
          <span>{dateRange}</span>
        )}
      </S.Detail>
      <S.Detail>
        <IconUsers size={20} />
        <span>
          {children
            ? [adultsDisplay, childrenDisplay].join(", ")
            : adultsDisplay}
        </span>
      </S.Detail>
    </S.Wrapper>
  );
};

export default HotelSearchCard;

const createFormData = (value: File | File[]) => {
  const formData = new FormData();

  if (Array.isArray(value)) {
    value.forEach(file => {
      formData.append("files[]", file);
    });
  } else {
    formData.append("file", value);
  }

  return formData;
};

export default createFormData;

import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  formatDateDayMonthShort,
  formatDateIso,
  startOfWeek,
} from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Price from "../../misc/Price";
import * as S from "./CalendarSelector.styled";

interface CalendarSelectorRange {
  from: Date;
  to: Date;
}

export interface CalendarSelectorProps {
  value: string;
  onChange: (date: string) => void;
  range: CalendarSelectorRange;
  calendar: { [date: string]: number };
  isLoading?: boolean;
}

const WEEK_DAYS = [
  { label: "components.calendarSelector.weekdays.monday" },
  { label: "components.calendarSelector.weekdays.tuesday" },
  { label: "components.calendarSelector.weekdays.wednesday" },
  { label: "components.calendarSelector.weekdays.thursday" },
  { label: "components.calendarSelector.weekdays.friday" },
  { label: "components.calendarSelector.weekdays.saturday" },
  { label: "components.calendarSelector.weekdays.sunday" },
];

const generateCalendarWeeks = (rangeFrom: Date, rangeTo: Date) => {
  const weeks = [];

  let startDate = startOfWeek(rangeFrom, { weekStartsOn: 1 });
  const endDate = endOfWeek(rangeTo, { weekStartsOn: 1 });

  do {
    const weekDays = eachDayOfInterval({
      start: startDate,
      end: addDays(startDate, 6),
    }).map(date => formatDateIso(date));

    weeks.push(weekDays);

    startDate = addDays(startDate, 7);
  } while (startDate <= endDate);

  return weeks;
};

const CalendarSelector = ({
  value,
  onChange,
  range,
  calendar,
  isLoading = false,
}: CalendarSelectorProps) => {
  const { t } = useTranslation();

  const calendarWeeks = useMemo(
    () => generateCalendarWeeks(range.from, range.to),
    [range],
  );

  const handleClick = (day: string) => {
    onChange(day);
  };

  const translatedWeekDays = useMemo(
    () =>
      WEEK_DAYS.map(day => ({
        ...day,
        label: t(day.label),
      })),
    [t],
  );

  return (
    <S.Calendar>
      <li>
        <S.Week>
          {translatedWeekDays.map((day, dayIndex) => (
            <S.DayTitle key={dayIndex}>
              <S.Date>{isLoading ? <Skeleton width={32} /> : day.label}</S.Date>
            </S.DayTitle>
          ))}
        </S.Week>
      </li>
      {calendarWeeks.map((week, weekIndex) => (
        <li key={weekIndex}>
          <S.Week>
            {isLoading && !calendar
              ? Array.from({ length: translatedWeekDays.length }).map(
                  (_, index) => (
                    <S.FlexGrow key={index}>
                      <Skeleton height={56} />
                    </S.FlexGrow>
                  ),
                )
              : week.map(day => {
                  const disabled = !calendar[day];

                  return (
                    <S.Day
                      key={day}
                      onClick={() => handleClick(day)}
                      disabled={disabled}
                      $active={value === day}
                    >
                      <S.Date>{formatDateDayMonthShort(day)}</S.Date>
                      {calendar[day] && (
                        <S.Price>
                          <Price value={calendar[day]} />
                        </S.Price>
                      )}
                    </S.Day>
                  );
                })}
          </S.Week>
        </li>
      ))}
    </S.Calendar>
  );
};

export default CalendarSelector;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaymentCreatePayload, PaymentService } from "../../services";

const useCreatePayment = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["payments", "create"],
    mutationFn: (payload: PaymentCreatePayload) => {
      return PaymentService.create(payload);
    },
    onSuccess: async (_, payload) => {
      const { reservationId } = payload;

      queryClient.invalidateQueries({
        queryKey: ["payments"],
      });

      queryClient.invalidateQueries({
        queryKey: ["reservations", payload.reservationId],
      });

      queryClient.invalidateQueries({
        queryKey: ["payments", { reservationId }],
      });

      queryClient.invalidateQueries({
        queryKey: ["invoices", { reservationId }],
      });

      queryClient.invalidateQueries({
        queryKey: ["invoices", { reservationId }],
      });

      // @todo: invalidate agency cache too for the credit balance
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCreatePayment;

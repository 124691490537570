import {
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useTransferSearchParams = () => {
  const [query, setQuery] = useQueryParams({
    type: NumberParam,
    pickUp: JsonParam,
    dropOff: JsonParam,
    outboundDate: StringParam,
    returnDate: StringParam,
    adults: NumberParam,
    children: JsonParam,
    useCache: BooleanParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useTransferSearchParams;

import { useMutation } from "@tanstack/react-query";

import { InvoiceService } from "../../services";

const useUploadInvoiceFile = (id: number) => {
  const mutation = useMutation({
    mutationKey: ["invoices", "upload_file"],
    mutationFn: (payload: FormData) => {
      return InvoiceService.uploadFile(id, payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadInvoiceFile;

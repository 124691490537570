import { TransferSearchPayload } from "@hotelspoint/api";
import { PlaceType, TransferTripType } from "@hotelspoint/types";
import { setTimeInUtc } from "@hotelspoint/utils";
import { array, date, InferType, mixed, number, object, string } from "yup";

import { PERSON_SELECTOR_CONFIG } from "./constants";

export const validationSchema = object({
  tripType: number(),
  pickUp: string()
    .required("searchTransfers.pickUp.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.pickUpPlace;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "searchTransfers.pickUp.validation.place",
        });
      }

      return true;
    }),
  dropOff: string()
    .required("searchTransfers.dropOff.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.dropOffPlace;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "searchTransfers.dropOff.validation.place",
        });
      }

      return true;
    }),
  outboundDate: date().required(
    "searchTransfers.outboundDate.validation.required",
  ),
  outboundTime: string().required(
    "searchTransfers.outboundTime.validation.required",
  ),
  returnDate: date().when("tripType", {
    is: TransferTripType.RoundTrip,
    then: schema => {
      return schema.required("searchTransfers.returnDate.validation.required");
    },
  }),
  returnTime: string().when("tripType", {
    is: TransferTripType.RoundTrip,
    then: schema => {
      return schema.required("searchTransfers.returnTime.validation.required");
    },
  }),
  travellers: object({
    adults: number()
      .min(1, "searchTransfers.travellers.validation.adult.min")
      .max(
        PERSON_SELECTOR_CONFIG.maxAdults,
        "searchTransfers.travellers.validation.adult.max",
      )
      .required(),
    children: array()
      .of(
        number()
          .min(1, "searchTransfers.travellers.validation.children.min")
          .max(
            PERSON_SELECTOR_CONFIG.maxChildrenAge,
            "searchTransfers.travellers.validation.children.max",
          )
          .required("searchTransfers.travellers.validation.children.required"),
      )
      .required(),
  }).required(),

  // Read-only - set programmatically when selecting the trip type and pick-up/drop-off places

  pickUpPlace: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
  dropOffPlace: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  tripType: TransferTripType.RoundTrip,
  pickUp: "",
  dropOff: "",
  outboundDate: undefined,
  returnDate: undefined,
  outboundTime: "10:30",
  returnTime: "10:30",
  travellers: { adults: 2, children: [] },
  // Read-only
  pickUpPlace: undefined,
  dropOffPlace: undefined,
};

export const form2Entity = (formValues: FormValues): TransferSearchPayload => {
  const areLocationsAirport = [
    formValues.pickUpPlace,
    formValues.dropOffPlace,
  ].every(place => place.type === PlaceType.Airport);

  return {
    type: areLocationsAirport
      ? TransferTripType.Airports
      : (formValues.tripType as TransferTripType),
    pickUp: {
      placeId: formValues.pickUpPlace.id as number,
      placeType: formValues.pickUpPlace.type as PlaceType,
    },
    dropOff: {
      placeId: formValues.dropOffPlace.id as number,
      placeType: formValues.dropOffPlace.type as PlaceType,
    },
    outboundDate: setTimeInUtc(
      formValues.outboundDate,
      formValues.outboundTime,
    ),
    returnDate:
      formValues.tripType === TransferTripType.RoundTrip
        ? setTimeInUtc(
            formValues.returnDate as Date,
            formValues.returnTime as string,
          )
        : undefined,
    adults: formValues.travellers.adults,
    children: formValues.travellers.children,
  };
};

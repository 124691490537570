import { ActivityOption } from "@hotelspoint/api";

interface Prices {
  totalPriceNet: number;
  totalPrice: number;
}

export const calculateTotalPrice = (
  option?: ActivityOption,
  tickets?: string | null,
) => {
  if (!option || !tickets) return { totalPriceNet: 0, totalPrice: 0 };

  const ticketEntries = tickets.split(",");

  return ticketEntries.reduce(
    (totals: Prices, ticket: string) => {
      const [key, numTickets] = ticket.split(":").map(Number);

      const matchingOption = option.prices.find(
        (option: { key: number }) => option.key === key,
      );

      if (matchingOption) {
        totals.totalPriceNet += matchingOption.priceNet * numTickets;
        totals.totalPrice += matchingOption.price * numTickets;
      }

      return totals;
    },
    { totalPriceNet: 0, totalPrice: 0 },
  );
};

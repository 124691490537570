import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Wrapper = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(1)}px;
  background-color: ${theme("palette.whiteSand")};
  padding: ${spacing(2)}px ${spacing(3)}px;
  border-radius: ${borderRadius.medium}px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: ${ifProp(
    "$active",
    ({ theme }) => `4px solid ${theme.palette.sky}`,
    "1px solid rgba(0, 0, 0, 0.15)",
  )};
`;

import { RateType } from "./module";
import { PlaceType } from "./place";

export interface HotelRoomGuests {
  adults: number;
  children: number[];
}

export interface HotelSearchQuery {
  placeId: number;
  placeType: PlaceType;
  checkIn: string;
  checkOut: string;
  nationality: string;
  rooms: HotelRoomGuests[];
}

export interface HotelRoomPromotions {
  amount?: number;
  name: string;
}

export interface HotelRoomRate {
  id: number;
  roomName: string;
  numRooms: number;
  price: number;
  priceNet: number;
  priceBase: number | null;
  adults: number;
  children: number[];
  packaging: boolean;
  promotions: boolean | HotelRoomPromotions[];
}

export interface HotelRateCancellation {
  rateType: RateType;
  deadline: string;
  deadlineSupplier: string;
}

export interface HotelRate {
  id: number;
  rateType: RateType;
  price: number;
  priceNet: number;
  priceBase: number | null;
  mealName: string;
  mealType: number;
  mealTypeName: string;
  surcharges: any | null;
  rooms: HotelRoomRate[];
  supplierId: number;
  hasPackaging: boolean;
  hasPromotion: boolean;
  hasTransfers: boolean;
  isExpedia: boolean;
}

export interface HotelRoom {
  name: string;
  minPrice: number;
  minPriceNet: number;
  rates: HotelRate[];
}

interface HotelBase {
  id: number;
  name: string;
  cityName: string;
  countryCode: string;
  rating: number;
  address: string;
  phone: string;
  email: string;
  url: string;
  latitude: number;
  longitude: number;
  thumbnail: string | null;
  isFavorite: boolean;
}

export interface HotelSearch extends HotelBase {
  minPrice: number;
  minPriceNet: number;
  maxPrice: number;
  maxPriceNet: number;
  cheapestRate: HotelRate;
}

export interface HotelAmenity {
  id: number;
  name: string;
  value: string;
}

export interface HotelInformation {
  label: string;
  text: string;
}

export interface Hotel extends HotelBase {
  images: string[];
  amenities: {
    room: HotelAmenity[];
    hotel: HotelAmenity[];
  };
  information: HotelInformation[];
}

// @todo: move this modal interfaces
export enum SearchHotelsResultsModalType {
  Details,
  Rooms,
  Gallery,
}

export interface SearchHotelsResultsModalState {
  id?: number;
  type: SearchHotelsResultsModalType;
}

import { TransferSearchQuery } from "@hotelspoint/types";
import { formatTimeUtc } from "@hotelspoint/utils";

import { FormValues } from "../../SearchTransfersForm/SearchTransfersForm.form";

export const entity2Form = (formValues: TransferSearchQuery): FormValues => ({
  tripType: formValues.type,
  pickUp: "",
  dropOff: "",
  outboundDate: new Date(formValues.outboundDate),
  outboundTime: formatTimeUtc(formValues.outboundDate),
  returnDate: formValues.returnDate
    ? new Date(formValues.returnDate)
    : undefined,
  returnTime: formValues.returnDate
    ? formatTimeUtc(formValues.returnDate)
    : undefined,
  travellers: {
    adults: formValues.adults,
    children: formValues.children,
  },
  pickUpPlace: {
    id: formValues.pickUp?.placeId,
    type: formValues.pickUp?.placeType,
  },
  dropOffPlace: {
    id: formValues.dropOff?.placeId,
    type: formValues.dropOff?.placeType,
  },
});

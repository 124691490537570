import { getStatusIconComponent, StatusFlavour } from "../../common";
import * as S from "./StatusBlock.styled";

interface StatusBlockProps {
  status: StatusFlavour;
  children: React.ReactNode;
}

const StatusBlock = ({ status, children }: StatusBlockProps) => {
  const IconComponent = getStatusIconComponent(status);

  return (
    <S.Wrapper $status={status}>
      <IconComponent size={22} />
      <div>{children}</div>
    </S.Wrapper>
  );
};

export default StatusBlock;

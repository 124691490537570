import { TransferSearchQuery } from "@hotelspoint/types";

import createRecentSearchesStore from "./utils/createRecentSearchesStore";

const PERSIST_NAME = "@hp::recent_transfer_searches";

export interface RecentTransferSearchQuery extends TransferSearchQuery {
  meta: {
    pickUpPlaceName: string;
    dropOffPlaceName: string;
  };
}

const useRecentTransferSearchesStore =
  createRecentSearchesStore<RecentTransferSearchQuery>({
    persistName: PERSIST_NAME,
  });

export default useRecentTransferSearchesStore;

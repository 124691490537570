import {
  HotelSearchInfoResponse,
  ReservationCreateNames,
  ReservationCreatePayload,
} from "@hotelspoint/api";
import { HotelRate } from "@hotelspoint/types";
import { setTimeInUtc } from "@hotelspoint/utils";
import { array, InferType, mixed, number, object, string } from "yup";
import { boolean } from "yup";

import { FormType } from "./types";

const personSchema = object({
  title: string().required(
    "searchHotelBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchHotelBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchHotelBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

const requiredIfHasTransfers = (message: string) => {
  return string().when("hasTransfers", {
    is: true,
    then: schema => schema.required(message),
  });
};

export const defaultValues = {
  type: FormType.AllPassengers,
  leader: [],
  guests: [],
  children: [],
  // Read-only
  hasTransfers: false,
  arrivalFlightNumber: "",
  arrivalFlightDate: undefined,
  arrivalFlightTime: "",
  returnFlightNumber: "",
  returnFlightDate: undefined,
  returnFlightTime: "",
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    leader: array().when("type", {
      is: FormType.LeaderOnly,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    guests: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    children: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    // Read-only
    hasTransfers: boolean(),
    arrivalFlightNumber: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightNumber.validation.required",
    ),
    arrivalFlightDate: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightDate.validation.required",
    ),
    arrivalFlightTime: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightTime.validation.required",
    ),
    returnFlightNumber: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightNumber.validation.required",
    ),
    returnFlightDate: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightDate.validation.required",
    ),
    returnFlightTime: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightTime.validation.required",
    ),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (
  values: FormValues,
  roomIds: number[],
): Pick<
  ReservationCreatePayload,
  | "names"
  | "arrivalFlightNumber"
  | "arrivalFlightDate"
  | "returnFlightNumber"
  | "returnFlightDate"
> => {
  const flightDetails = values.hasTransfers
    ? {
        arrivalFlightNumber: values.arrivalFlightNumber,
        arrivalFlightDate: setTimeInUtc(
          values.arrivalFlightDate as string,
          values.arrivalFlightTime as string,
        ),
        returnFlightNumber: values.returnFlightNumber,
        returnFlightDate: setTimeInUtc(
          values.returnFlightDate as string,
          values.returnFlightTime as string,
        ),
      }
    : {};

  if (values.type === FormType.LeaderOnly && values.leader) {
    return {
      names: values.leader.map((leader, roomId) => ({
        roomId: roomIds[roomId],
        adults: leader,
        children: [],
      })),
      ...flightDetails,
    };
  }

  return {
    names: values.guests?.map((adults, roomId) => ({
      roomId: roomIds[roomId],
      adults,
      children: values.children?.[roomId] || [],
    })) as ReservationCreateNames[],
    ...flightDetails,
  };
};

export const entity2Form = (
  hotelRate: HotelRate,
  searchInfo: HotelSearchInfoResponse,
): FormValues => {
  return {
    type: FormType.AllPassengers,
    leader: [],
    guests: [],
    children: hotelRate.rooms
      .map((room: any) =>
        Array.from({ length: room.numRooms }).map(() =>
          room.children.map((child: any) => ({
            age: child,
          })),
        ),
      )
      .flat(),
    hasTransfers: hotelRate.hasTransfers,
    arrivalFlightNumber: "",
    arrivalFlightDate: searchInfo.search.checkIn,
    arrivalFlightTime: "",
    returnFlightNumber: "",
    returnFlightDate: searchInfo.search.checkOut,
    returnFlightTime: "",
  };
};

import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const SectionTitle = styled.h6`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin: ${spacing(1)}px 0;
  font-weight: 600;
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FormFooter = styled.div`
  margin-top: ${spacing(3)}px;
  column-gap: ${spacing(2)}px;
  button {
    max-width: 100%;
    @media ${mediaQuery.desktop} {
      max-width: fit-content;
    }
  }
  @media ${mediaQuery.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const FormPriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.desktop} {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;

export const PriceTotal = styled(Price)`
  font-size: ${pxToRem(20)};
`;

import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormSelect,
} from "@hotelspoint/components";
import { TITLE_OPTIONS } from "@hotelspoint/constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

export enum PassengerType {
  Adult = "adult",
  Children = "children",
}

interface PassengerInfoProps {
  passengerIndex?: number;
  name?: string;
  type?: PassengerType;
  isLoading?: boolean;
}

const PassengerInfo = ({
  passengerIndex,
  name,
  type,
  isLoading,
}: PassengerInfoProps) => {
  const { t } = useTranslation();

  const titleOptions = useMemo(
    () =>
      TITLE_OPTIONS.map(title => ({
        ...title,
        label: t(title.label),
      })),
    [t],
  );

  return (
    <Flex mx={[0, 0, -1, -1]}>
      <Box width={[1, 1, 2 / 14]} px={[0, 0, 1, 1]} py={1}>
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <FormAdapter
            name={`${name}.title`}
            label={
              passengerIndex === 0
                ? t("searchHotelBook.content.form.title.label")
                : undefined
            }
          >
            {props => (
              <FormSelect
                {...props}
                placeholder={t(
                  "searchHotelBook.content.form.title.placeholder",
                )}
                options={titleOptions}
                isSearchable={false}
              />
            )}
          </FormAdapter>
        )}
      </Box>
      <Box
        width={
          type === PassengerType.Children ? [1, 1, 5 / 14] : [1, 1, 6 / 14]
        }
        px={[0, 0, 1, 1]}
        py={1}
      >
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <FormAdapter
            name={`${name}.name`}
            label={
              passengerIndex === 0
                ? t("searchHotelBook.content.form.firstName.label")
                : undefined
            }
          >
            {props => (
              <FormClearableInput
                {...props}
                placeholder={t(
                  "searchHotelBook.content.form.firstName.placeholder",
                )}
              />
            )}
          </FormAdapter>
        )}
      </Box>
      <Box
        width={
          type === PassengerType.Children ? [1, 1, 5 / 14] : [1, 1, 6 / 14]
        }
        px={[0, 0, 1, 1]}
        py={1}
      >
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <FormAdapter
            name={`${name}.surname`}
            label={
              passengerIndex === 0
                ? t("searchHotelBook.content.form.lastName.label")
                : undefined
            }
          >
            {props => (
              <FormClearableInput
                {...props}
                placeholder={t(
                  "searchHotelBook.content.form.lastName.placeholder",
                )}
              />
            )}
          </FormAdapter>
        )}
      </Box>
      {type === PassengerType.Children && (
        <Box width={[1, 1, 2 / 14]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name={`${name}.age`}
              label={
                passengerIndex === 0
                  ? t("searchHotelBook.content.form.age.label")
                  : undefined
              }
            >
              {props => (
                <FormClearableInput {...props} type="number" readOnly />
              )}
            </FormAdapter>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default PassengerInfo;

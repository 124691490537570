import {
  ActivitySearchCard,
  Box,
  Container,
  Flex,
  HeaderImage,
  PageWrapper,
  Tooltip,
} from "@hotelspoint/components";
import {
  RecentActivitySearchQuery,
  useRecentActivitiesSearchesStore,
} from "@hotelspoint/store";
import {
  formatDateIso,
  isDateBefore,
  useDocumentTitle,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import headerImage from "./assets/header-bg.jpg";
import * as S from "./SearchActivities.styled";
import SearchActivitiesForm from "./SearchActivitiesForm";
import SearchActivitiesResults from "./SearchActivitiesResults";
import useActivitySearchParams from "./useActivitySearchParams";

const STUB_CHECK_OUT_ADVANCE = 3;

const SearchActivities = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchActivities.pageTitle"));

  const [initialSearch, setInitialSearch] = useState<any>(undefined);

  const [query, setQuery] = useActivitySearchParams();

  const recentSearches = useRecentActivitiesSearchesStore(
    state => state.queries,
  );

  const dateToday = useMemo(() => new Date(), []);
  const dateInThreeDays = useMemo(() => {
    const value = new Date();
    value.setDate(value.getDate() + STUB_CHECK_OUT_ADVANCE);

    return value;
  }, []);

  // Populate the recent searches with stub data for the suggestions
  const recentSearchOptions = useMemo(() => {
    return recentSearches.map(query => ({
      ...query,
      dateIn: query.dateIn || formatDateIso(dateToday),
      dateOut: query.dateOut || formatDateIso(dateInThreeDays),
    }));
  }, [recentSearches, dateToday, dateInThreeDays]);

  const handleRecentSearch = useCallback(
    (query: RecentActivitySearchQuery) => {
      const isDateInOutdated = isDateBefore(new Date(query.dateIn), dateToday);

      // Outdated queries are partially loaded into the form as initial values
      if (isDateInOutdated) {
        setInitialSearch({
          search: query.meta.placeName,
          travelers: {
            adults: query.adults,
            children: query.children,
          },
          place: {
            id: query.placeId,
            type: query.placeType,
          },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
      } else {
        setQuery(omit(query, "meta"));
      }
    },
    [dateToday, setQuery],
  );

  if (query.placeId !== undefined && query.placeType !== undefined) {
    return <SearchActivitiesResults />;
  }

  return (
    <PageWrapper>
      <HeaderImage src={headerImage}>
        <Container>
          <S.HeaderWrapper>
            <S.HeaderCard>
              <SearchActivitiesForm initialValues={initialSearch} />
            </S.HeaderCard>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
      {recentSearches.length !== 0 && (
        <Container>
          <div style={{ marginTop: 48 }}>
            <Tooltip>
              <Tooltip.Trigger>
                <h4 style={{ marginBottom: 16 }}>
                  <span>{t("searchActivities.recentSearches.title")}</span>
                </h4>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {t("searchActivities.recentSearches.tooltip")}
              </Tooltip.Content>
            </Tooltip>
            <Flex mx={[0, -1, -1, -1]} my={[-1, -1, -1, -1]}>
              {recentSearchOptions.map((query, index) => (
                <Box
                  key={`recent-search-${index}`}
                  width={[1, 1 / 2, 1 / 2, 1 / 3]}
                  px={[0, 1, 1, 1]}
                  py={[1, 1, 1, 1]}
                >
                  <button
                    type="button"
                    onClick={() => handleRecentSearch(query)}
                    style={{ width: "100%" }}
                  >
                    <ActivitySearchCard
                      type={query.placeType}
                      name={query.meta.placeName}
                      dateIn={query.dateIn}
                      dateOut={query.dateOut}
                      adults={query.adults}
                      children={query.children.length}
                    />
                  </button>
                </Box>
              ))}
            </Flex>
          </div>
        </Container>
      )}
    </PageWrapper>
  );
};

export default SearchActivities;

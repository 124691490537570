import "react-day-picker/dist/style.css";

import { daysBetween, formatDate } from "@hotelspoint/utils";
import { IconCalendar } from "@tabler/icons-react";
import classNames from "classnames";
import { useMemo, useState } from "react";
import {
  DayPicker,
  type DayPickerRangeProps,
  type DayPickerSingleProps,
} from "react-day-picker";
import { useTranslation } from "react-i18next";

import Popover from "../../../composites/Popover";
import FormClearableInput from "../../FormClearableInput";
import { type FormControlInjectedProps } from "../../FormControl";
import * as S from "./FormDatePicker.styled";

interface FormDatePickerProps extends FormControlInjectedProps {
  disabled?: boolean;
  readOnly?: boolean;
  dayPickerProps: DayPickerSingleProps | DayPickerRangeProps;
}

function FormDatePicker({
  value,
  onChange,
  disabled = false,
  readOnly,
  dayPickerProps,
  ...rest
}: FormDatePickerProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { mode } = dayPickerProps;

  const rangeDays = useMemo(() => {
    if (mode !== "range" || !value?.from || !value?.to) return 0;

    return daysBetween({
      from: value.from,
      to: value.to,
    });
  }, [mode, value]);

  const hasValue = useMemo(() => {
    if (mode === "single") return !!value;

    return rangeDays > 0;
  }, [mode, value, rangeDays]);

  const displayValue = useMemo(() => {
    switch (mode) {
      case "single":
        return value ? formatDate(value) : "";

      case "range":
        if (!value?.from && !value?.to) return "";
        if (value?.from && !value.to) return formatDate(value.from);

        return `${formatDate(value.from)} - ${formatDate(value.to)}`;

      default:
        return "";
    }
  }, [mode, value]);

  const tooltip = useMemo(() => {
    if (mode !== "range" || !hasValue) return undefined;

    return `${rangeDays} ${t("components.formDatePicker.night", {
      count: rangeDays,
    })}`;
  }, [t, mode, hasValue, rangeDays]);

  const handleSelect = (newValue: any, selectedDay: Date) => {
    // @todo: Behaviour will change in the next major version - v9
    // https://github.com/gpbl/react-day-picker/issues/2140#issuecomment-2129196501
    if (mode === "range") {
      if (value?.from && value?.to) {
        onChange({
          from: selectedDay,
        });
      } else {
        onChange(newValue);
      }
    } else {
      onChange(newValue);
    }

    // on first date selection the value is undefined and the popup is not closing
    if (value && mode === "single") {
      setIsOpen(false);
    }
  };

  const handleTriggerClick = () => {
    setIsOpen(!disabled && !readOnly);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-start">
      <Popover.Trigger onClick={handleTriggerClick}>
        <FormClearableInput
          value={displayValue}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          startAdornment={<IconCalendar size={18} />}
          style={{ userSelect: "none" }}
          {...rest}
        />
      </Popover.Trigger>
      <Popover.Content>
        <S.Styles $tooltip={tooltip}>
          <S.PaperWrapper>
            <DayPicker
              selected={value}
              onSelect={handleSelect}
              modifiersClassNames={{
                selected: "hp-selected",
                range_start: "hp-range-start",
                range_end: classNames("hp-range-end", {
                  "hp-range-active": rangeDays > 0,
                }),
                disabled: "hp-disabled",
              }}
              {...dayPickerProps}
            />
            {mode === "range" && (
              <S.Footer>
                {rangeDays > 0
                  ? t("components.formDatePicker.selection", {
                      count: rangeDays,
                      days: rangeDays,
                    })
                  : t("components.formDatePicker.noSelection")}
              </S.Footer>
            )}
          </S.PaperWrapper>
        </S.Styles>
      </Popover.Content>
    </Popover>
  );
}

export default FormDatePicker;

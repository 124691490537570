import { offset } from "@floating-ui/react";
import { useTranslation } from "react-i18next";

import Price from "../../misc/Price";
import Popover from "../Popover";
import * as S from "./MarkupPrice.styled";

interface MarkupPriceValue {
  net: number;
  total: number;
}

interface MarkupPriceProps {
  value: MarkupPriceValue;
  children: React.ReactNode;
}

const MarkupPrice = ({ value, children }: MarkupPriceProps) => {
  const { t } = useTranslation();

  return (
    <Popover
      placement="top"
      middleware={[
        offset(16),
        // arrow({
        //   element: arrowRef,
        // }),
      ]}
    >
      <Popover.Trigger style={{ textAlign: "center" }}>
        {children}
      </Popover.Trigger>
      <Popover.Content>
        <S.Wrapper $elevation={4}>
          <S.Item>
            <S.Label>{t("components.markupPrice.price.net")}</S.Label>
            <S.ItemPrice>
              <Price value={value.net} />
            </S.ItemPrice>
          </S.Item>
          <S.Item>
            <S.Label>{t("components.markupPrice.price.markup")}</S.Label>
            <S.ItemPrice>
              <Price value={value.total - value.net} />
            </S.ItemPrice>
          </S.Item>
        </S.Wrapper>
      </Popover.Content>
    </Popover>
  );
};

export default MarkupPrice;

import { PlaceType } from "@hotelspoint/types";
import {
  IconAddressBook,
  IconBed,
  IconBuildingCommunity,
  IconFileUnknown,
  IconMap,
  IconPinned,
  IconPlane,
  type IconProps,
} from "@tabler/icons-react";

const getPlaceTypeIcon = (type: PlaceType): any => {
  switch (type) {
    case PlaceType.Area:
      return IconMap;

    case PlaceType.City:
    case PlaceType.Destination:
      return IconBuildingCommunity;

    case PlaceType.Hotel:
      return IconBed;

    case PlaceType.Airport:
      return IconPlane;

    case PlaceType.PointOfInterest:
      return IconPinned;

    case PlaceType.Address:
      return IconAddressBook;

    default:
      return IconFileUnknown;
  }
};

interface PlaceTypeIconProps {
  type: PlaceType;
  iconProps?: IconProps;
}

const PlaceTypeIcon = ({ type, iconProps }: PlaceTypeIconProps) => {
  const IconComponent = getPlaceTypeIcon(type);

  return <IconComponent {...iconProps} />;
};

export default PlaceTypeIcon;

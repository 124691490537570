import { ActivityOptionQuestion } from "@hotelspoint/api";
import { SCHEMA_EMAIL } from "@hotelspoint/utils";
import { array, InferType, number, object, string } from "yup";

const personSchema = object({
  title: string().required(
    "searchHotelsBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchHotelsBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchHotelsBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

export const defaultValues = {
  adults: [],
  children: [],
  phoneCode: "+359",
  phoneNumber: "",
  email: "",
};

export const validationSchema = object()
  .shape({
    adults: array().of(personSchema).required(),
    children: array().of(personSchema).required(),
    phoneCode: string().required(
      "searchActivityBook.content.form.phoneCode.validation.required",
    ),
    phoneNumber: string().required(
      "searchActivityBook.content.form.phoneNumber.validation.required",
    ),
    email: SCHEMA_EMAIL,
  })
  .required();

export const getDynamicValidationSchema = (
  fieldConfig: ActivityOptionQuestion[],
) => {
  const dynamicSchema = validationSchema;

  const dynamicFields = fieldConfig.reduce(
    (accumulator: { [key: string]: any }, field) => {
      accumulator[field.code] = field.required ? string().required() : string();
      return accumulator;
    },
    {},
  );

  return dynamicSchema.shape(dynamicFields);
};

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (values: FormValues) => {
  return {
    names: values.adults.map(adults => ({
      adults,
      children: values.children,
    })),
  };
};

export const entity2Form = (children: number[]): FormValues => {
  return {
    adults: [],
    children: children.map((child: number) => {
      return {
        title: "",
        name: "",
        surname: "",
        age: child,
      };
    }),
    phoneCode: "",
    phoneNumber: "",
    email: "",
  };
};

import { IconCloudUpload } from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import { DropzoneProps, useDropzone } from "react-dropzone";

import FileList from "../../composites/FileList";
import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormDropZone.styled";

interface FormDropZoneProps extends Omit<FormControlInjectedProps, "value"> {
  value?: File | File[];
  disabled?: boolean;
  placeholder?: string;
  dropZoneProps?: Partial<DropzoneProps>;
}

const FormDropZone = ({
  value = [],
  onChange,
  error,
  disabled = false,
  placeholder,
  dropZoneProps,
}: FormDropZoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    multiple: true,
    onDrop: files => {
      if (dropZoneProps?.multiple) {
        onChange([...(value as File[]), ...files]);
      } else {
        const [file] = files;
        onChange(file);
      }
    },
    ...dropZoneProps,
  });

  const hasFiles = useMemo(() => {
    return Array.isArray(value) ? value.length > 0 : !!value;
  }, [value]);

  const handleRemoveFile = useCallback(
    (file: File | File[]) => {
      if (Array.isArray(value)) {
        const newValue = value.filter((f: File) => f !== file);

        onChange(newValue);
      } else {
        onChange(null);
      }
    },
    [value, onChange],
  );

  return (
    <div>
      <S.Wrapper {...getRootProps()} $disabled={disabled} $error={!!error}>
        <input {...getInputProps()} />
        <IconCloudUpload size={32} />
        <S.Placeholder>{placeholder}</S.Placeholder>
      </S.Wrapper>
      {hasFiles && (
        <S.FilesWrapper>
          <FileList
            value={Array.isArray(value) ? value : [value]}
            onRemove={handleRemoveFile}
          />
        </S.FilesWrapper>
      )}
    </div>
  );
};

export default FormDropZone;

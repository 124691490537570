import { Paper } from "@hotelspoint/components";
import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

export const Wrapper = styled(Paper)`
  padding: ${spacing(1)}px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  row-gap: ${spacing(1)}px;
  padding: ${spacing(1)}px;

  @media ${mediaQuery.tablet} {
    row-gap: 0;
    padding: ${spacing(2)}px;
  }
`;

export const WrapperDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterHeader = styled.h6`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${pxToRem(16)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const FooterDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing(1.25)}px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
  white-space: nowrap;
`;

export const PriceWrapper = styled.span`
  display: flex;
  font-size: ${pxToRem(20)};
  font-weight: 500;
  gap: ${spacing(1.25)}px;
`;

export const Provider = styled.div`
  font-size: ${pxToRem(18)};
  font-weight: bold;
`;

export const Passengers = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
`;

export const Title = styled.h6`
  color: ${theme("palette.ocean")};
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
`;

export const Thumbnail = styled.div<{ $src: string | null }>`
  min-height: 160px;
  min-width: 300px;
  margin-left: 0;
  background: url(${prop("$src")}) no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: ${ifProp("$src", "pointer", "default")};

  @media ${mediaQuery.tablet} {
    min-width: 300px;
    height: 100%;
    margin-left: ${spacing(1.5)}px;
  }
`;

import { ActivityDetails } from "@hotelspoint/api";
import { GalleryOverlay } from "@hotelspoint/components";
import {
  SearchActivitiesResultsModalState,
  SearchActivitiesResultsModalType,
} from "@hotelspoint/types";
import { ImageType } from "@hotelspoint/utils";

import ActivityDetailsModal from "./ActivityDetailsModal";

interface ActivityDetailsGalleryModalProps {
  activity?: ActivityDetails;
  isLoading: boolean;
  activeModal?: SearchActivitiesResultsModalState;
  initialGalleryIndex?: number;
  setInitialGalleryIndex?: (index: number) => void;
  setActiveModal: (
    modal: SearchActivitiesResultsModalState | undefined,
  ) => void;
}

const ActivityDetailsGalleryModal = ({
  activity,
  isLoading,
  activeModal,
  initialGalleryIndex = 0,
  setInitialGalleryIndex,
  setActiveModal,
}: ActivityDetailsGalleryModalProps) => {
  const handleImageClick = (index: number) => {
    setInitialGalleryIndex && setInitialGalleryIndex(index);
    setActiveModal({
      id: activity?.id,
      type: SearchActivitiesResultsModalType.Gallery,
    });
  };

  const handleSelectActivity = () => {
    setActiveModal({
      id: activity?.id,
      type: SearchActivitiesResultsModalType.Book,
    });
  };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={
          activeModal?.type === SearchActivitiesResultsModalType.Gallery &&
          activity?.activity.images?.length !== 0
        }
        images={activity?.activity.images ?? []}
        imageType={ImageType.Activity}
        initialSlideIndex={initialGalleryIndex}
        onClose={handleCloseModal}
      />
      <ActivityDetailsModal
        data={activity as ActivityDetails}
        isOpen={activeModal?.type === SearchActivitiesResultsModalType.Details}
        isLoading={isLoading}
        handleImageClick={handleImageClick}
        handleSelectActivity={handleSelectActivity}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default ActivityDetailsGalleryModal;

import { useTranslation } from "react-i18next";

import Button from "../../elements/Button";
import * as S from "./ActivityOption.styled";

export interface ActivityOptionProps {
  name: string;
  isDisabled?: boolean;
  value: boolean;
  onChange?: () => void;
}

const ActivityOption = ({
  name,
  isDisabled = false,
  value,
  onChange,
}: ActivityOptionProps) => {
  const { t } = useTranslation();
  return (
    <S.Wrapper $active={value}>
      <p>{name}</p>
      {value || isDisabled ? (
        <Button
          variant="outlined"
          onClick={onChange}
          isDisabled={isDisabled}
          small
        >
          {t("components.activityOption.change")}
        </Button>
      ) : (
        <Button variant="outlined" onClick={onChange} small>
          {t("components.activityOption.select")}
        </Button>
      )}
    </S.Wrapper>
  );
};

export default ActivityOption;

import { handleError, useAgency, useUpdateAgency } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
  OptionGroup,
} from "@hotelspoint/components";
import { countryGroups } from "@hotelspoint/constants";
import { useIsUserRole, useUserProfileStore } from "@hotelspoint/store";
import { UserCurrency, UserProfile, UserRole } from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconMail, IconPercentage, IconUser } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import ProfileAgencyLogo from "./ProfileAgencyLogo";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./ProfileAgencySettings.form";
import * as S from "./ProfileAgencySettings.styled";

const ProfileAgencySettings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileAgencySettings.pageTitle"));

  const profile = useUserProfileStore(state => state.profile) as UserProfile;
  const isAgencyMember = useIsUserRole(UserRole.AgencyMember);

  const [agency] = useAgency(profile?.agencyId as number);
  const [updateAgency, isUpdatingAgency] = useUpdateAgency(profile?.agencyId);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    if (!agency) return defaultValues;

    return entity2Form(agency);
  }, [agency]);

  if (!agency) {
    return <LoaderBlock />;
  }

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = form2Entity(formValues);

      await updateAgency(payload);

      toast.success(t("toast.profileAgencySettings.details"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  if (isAgencyMember) {
    return <Navigate to="/profile/settings/agent" replace={true} />;
  }

  return (
    <>
      <ProfileAgencyLogo />
      <Form<FormValues>
        defaultValues={formValues}
        validationSchema={validationSchema}
      >
        <FormLayout>
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="name"
                label={t("profileAgencySettings.name.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("profileAgencySettings.name.placeholder")}
                    startAdornment={<IconUser size={18} />}
                    disabled={isUpdatingAgency}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="email"
                label={t("profileAgencySettings.email.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("profileAgencySettings.email.placeholder")}
                    startAdornment={<IconMail size={18} />}
                    readOnly
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyPhone"
                label={t("profileAgencySettings.companyPhone.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.companyPhone.placeholder",
                    )}
                    disabled={isUpdatingAgency}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="countryCode"
                label={t("profileAgencySettings.countryCode.label")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.countryCode.placeholder",
                    )}
                    options={countryOptions}
                    readOnly
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="uicNumber"
                label={t("profileAgencySettings.uicNumber.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.uicNumber.placeholder",
                    )}
                    readOnly
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyLicense"
                label={t("profileAgencySettings.companyLicense.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.companyLicense.placeholder",
                    )}
                    disabled={isUpdatingAgency}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyManager"
                label={t("profileAgencySettings.companyManager.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.companyManager.placeholder",
                    )}
                    disabled={isUpdatingAgency}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyAddress"
                label={t("profileAgencySettings.companyAddress.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileAgencySettings.companyAddress.placeholder",
                    )}
                    disabled={isUpdatingAgency}
                  />
                )}
              </FormAdapter>
            </Box>
            <S.Separator />
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupHotels"
                label={t("profileAgencySettings.markupHotels.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={isUpdatingAgency}
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupTransfers"
                label={t("profileAgencySettings.markupTransfers.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={isUpdatingAgency}
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupActivities"
                label={t("profileAgencySettings.markupActivities.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={isUpdatingAgency}
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditUsed"
                label={t("profileAgencySettings.creditUsed.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    readOnly
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditBalance"
                label={t("profileAgencySettings.creditBalance.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    readOnly
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isUpdatingAgency}
                onClick={handleSubmit(onSubmit)}
              >
                {t("profileAgencySettings.submit")}
              </Button>
            )}
          />
        </FormLayout>
      </Form>
    </>
  );
};

export default ProfileAgencySettings;

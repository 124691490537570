import {
  handleError,
  PaymentType,
  useUploadPaymentFile,
} from "@hotelspoint/api";
import { useUserAgencyStore } from "@hotelspoint/store";
import { UserCurrency } from "@hotelspoint/types";
import { createFormData, getPaymentTypeName } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StatusFlavour } from "../../common";
import Button from "../../elements/Button";
import StatusBlock from "../../elements/StatusBlock";
import Form from "../../form/Form";
import FormAdapter from "../../form/FormAdapter";
import FormCheckbox from "../../form/FormCheckbox";
import FormContext from "../../form/FormContext";
import FormDropZone from "../../form/FormDropZone";
import FormRadio from "../../form/FormRadio";
import * as S from "./PaymentOptions.styled";
import {
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./PaymentOptions.util";

export interface PaymentOptionsProps {
  amount: number;
  onConfirm: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
  activeTypes?: PaymentType[];
}

const PaymentOptionsInner = ({
  amount,
  onConfirm,
  onCancel,
  activeTypes,
  isLoading = false,
}: PaymentOptionsProps) => {
  const { t } = useTranslation();

  const [uploadFile, isUploading] = useUploadPaymentFile();

  const [typeInForm] = useWatch({ name: ["type"] });
  const type = Number(typeInForm);

  const agency = useUserAgencyStore(state => state.agency);
  const balance = agency?.credit?.balance ?? 0;

  const hasInsufficientBalance = useMemo(
    () => balance < amount,
    [amount, balance],
  );

  const isSubmitting = isLoading || isUploading;

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        // Upload a payment file if needed
        let fileId;
        if (values.file) {
          const formData = createFormData(values.file);
          const payload = await uploadFile(formData);

          fileId = payload.fileId;
        }

        const payload = form2Entity({
          ...values,
          fileId,
        });

        onConfirm(payload);
      } catch (err: any) {
        handleError({ t, error: err });
      }
    },
    [t, uploadFile, onConfirm],
  );

  return (
    <S.Wrapper>
      <FormAdapter
        name="type"
        label={t("components.paymentOptions.type.label")}
      >
        {props => (
          <S.RadioGroup>
            {[
              PaymentType.CreditLine,
              PaymentType.BankTransfer,
              PaymentType.CreditCard,
            ]
              .filter(type => !activeTypes || activeTypes.includes(type))
              .map(type => (
                <FormRadio
                  {...props}
                  key={type}
                  id={`${type}`}
                  value={type}
                  label={t(
                    `components.paymentOptions.${getPaymentTypeName(type)}`,
                  )}
                  defaultChecked={props.value === type}
                  disabled={isSubmitting}
                />
              ))}
          </S.RadioGroup>
        )}
      </FormAdapter>
      {type === PaymentType.CreditLine && (
        <>
          <StatusBlock status={StatusFlavour.Info}>
            {t("components.paymentOptions.creditLine.info", {
              value: balance,
              currency: UserCurrency.EUR,
            })}
          </StatusBlock>
          {hasInsufficientBalance && (
            <StatusBlock status={StatusFlavour.Error}>
              {t("components.paymentOptions.creditLine.insufficient")}
            </StatusBlock>
          )}
          <FormAdapter
            name="confirmCreditLine"
            label={t("components.paymentOptions.confirmCreditLine.label")}
            formControlProps={{ labelPosition: "inline-end" }}
          >
            {props => (
              <FormCheckbox
                {...props}
                disabled={isSubmitting || hasInsufficientBalance}
              />
            )}
          </FormAdapter>
        </>
      )}
      {type === PaymentType.BankTransfer && (
        <FormAdapter
          name="file"
          label={t("components.paymentOptions.file.label")}
        >
          {props => (
            <FormDropZone
              {...props}
              placeholder={t("components.paymentOptions.file.placeholder")}
              disabled={isSubmitting}
              dropZoneProps={{ multiple: false }}
            />
          )}
        </FormAdapter>
      )}
      {type === PaymentType.CreditCard && (
        <>
          <StatusBlock status={StatusFlavour.Info}>
            {t("components.paymentOptions.creditCard.info")}
          </StatusBlock>
          <FormAdapter
            name="currency"
            label={t("components.paymentOptions.currency.label")}
          >
            {props => (
              <S.RadioGroup>
                {[UserCurrency.EUR, UserCurrency.BGN].map(currency => (
                  <FormRadio
                    {...props}
                    key={currency}
                    id={currency}
                    value={currency}
                    label={currency}
                    defaultChecked={props.value === currency}
                    disabled={isSubmitting}
                  />
                ))}
              </S.RadioGroup>
            )}
          </FormAdapter>
        </>
      )}
      <S.Actions>
        <Button type="submit" variant="outlined" onClick={onCancel}>
          {t("components.paymentOptions.cancel")}
        </Button>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="tertiary"
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t("components.paymentOptions.confirm")}
            </Button>
          )}
        />
      </S.Actions>
    </S.Wrapper>
  );
};

const PaymentOptions = (props: PaymentOptionsProps) => {
  const agency = useUserAgencyStore(state => state.agency);

  const initialValues = useMemo(() => {
    return entity2Form({
      countryCode: agency?.countryCode as string,
    });
  }, [agency?.countryCode]);

  return (
    <Form
      defaultValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      showDevTool
    >
      {formMethods => <PaymentOptionsInner {...formMethods} {...props} />}
    </Form>
  );
};

export default PaymentOptions;

import { AdminCurrency } from "@hotelspoint/api";
import { parseRate } from "@hotelspoint/utils";

export const entity2Form = (currencies: AdminCurrency[]) => {
  return Object.fromEntries(
    currencies.map(currency => [currency.id, parseRate(currency.rate)]),
  );
};

export const form2Entity = (value: number) => ({
  rate: Number(parseRate(value)),
});

import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useSearchActivityBookParams = () => {
  const [query, setQuery] = useQueryParams({
    searchId: NumberParam,
    date: StringParam,
    languages: StringParam,
    session: StringParam,
    option: NumberParam,
    tickets: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useSearchActivityBookParams;

import {
  Transfer,
  TransferSearchPayload,
  usePlace,
  useTransferAvailabilitySearch,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  LoaderBlock,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  TransferListing,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconCar, IconReload, IconSearch } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import useTransferSearchParams from "../useTransferSearchParams";
import SearchTransfersForm from "./SearchTransfersForm";
import * as S from "./SearchTransfersResults.styled";
import TransferDetailsModal from "./TransferDetailsModal";

const SearchTransfersResults = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransfersResults.pageTitle"));

  const [query] = useTransferSearchParams();
  const [pageIndex, setPageIndex] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<any | null>(null);

  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [transfersCount, setTransfersCount] = useState<number | undefined>(
    undefined,
  );

  const [search, isLoadingSearch, { refetch }] = useTransferAvailabilitySearch(
    query as TransferSearchPayload,
  );

  const [pickUpPlace, isLoadingPickUpPlace] = usePlace({
    id: query.pickUp.placeId as number,
    type: query.pickUp.placeType as PlaceType,
  });

  const [dropOffPlace, isLoadingDropOffPlace] = usePlace({
    id: query.dropOff.placeId as number,
    type: query.dropOff.placeType as PlaceType,
  });

  const isLoadingPlaces = isLoadingPickUpPlace || isLoadingDropOffPlace;

  const canLoadMore = useMemo(() => {
    return transfers?.length !== 0 && transfers?.length !== transfersCount;
  }, [transfers, transfersCount]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchTransfers.pageTitle"),
        href: "/search/transfers",
        icon: () => <IconCar size={18} />,
      },
      {
        title: t("searchTransfersResults.search"),
        icon: () => <IconSearch size={18} />,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (search) {
      const { results, total } = search;

      // Update the initial set of transfers & count
      setTransfers(results);
      setTransfersCount(total);

      // Reset the page index when a new search is made
      setPageIndex(1);
    }
  }, [search]);

  return (
    <PageWrapper>
      <TransferDetailsModal
        transfer={selectedTransfer}
        isOpen={isModalOpen}
        isLoading={false}
        onClose={() => setIsModalOpen(false)}
      />
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.SidebarCard>
                <SearchTransfersForm refetch={refetch} />
              </S.SidebarCard>
              <h4>Filters</h4>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <h3>
                {isLoadingSearch || isLoadingPlaces ? (
                  <Skeleton height={20} />
                ) : (
                  t("searchTransfersResults.count", {
                    count: transfersCount,
                    pickUpPlace: pickUpPlace?.name,
                    dropOffPlace: dropOffPlace?.name,
                  })
                )}
              </h3>
              <S.TransferListingWrapper>
                {isLoadingSearch ? (
                  <LoaderBlock>
                    <h6>{t("searchTransfersResults.loading")}</h6>
                  </LoaderBlock>
                ) : (
                  <>
                    {transfers.map(transfer => (
                      <TransferListing
                        key={transfer.id}
                        id={transfer.id}
                        name={transfer.name}
                        vehicle={transfer.vehicle}
                        type={transfer.type}
                        maxPassengers={transfer.maxPassengers}
                        provider={transfer.provider}
                        modificationTime={transfer.modificationTime}
                        cancellationTime={transfer.cancellationTime}
                        image={transfer.image}
                        price={transfer.price}
                        priceNet={transfer.priceNet}
                        handleShowInformation={() => {
                          setSelectedTransfer(transfer);
                          setIsModalOpen(true);
                        }}
                        handleBookClick={() => {
                          console.log("Show book");
                        }}
                      />
                    ))}
                    {canLoadMore && (
                      <S.LoadMoreWrapper>
                        <Button
                          variant="outlined"
                          onClick={() => setPageIndex(pageIndex + 1)}
                          isLoading={isLoadingSearch}
                        >
                          {!isLoadingSearch && <IconReload size={18} />}
                          <span>{t("searchTransfersResults.loadMore")}</span>
                        </Button>
                      </S.LoadMoreWrapper>
                    )}
                  </>
                )}
              </S.TransferListingWrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransfersResults;

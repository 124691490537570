import { useMutation } from "@tanstack/react-query";

import { AuthService, UploadDocumentsParams } from "../../services";

const useUploadDocuments = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "upload_documents"],
    mutationFn: ({
      payload,
      params,
    }: {
      payload: FormData;
      params?: Partial<UploadDocumentsParams>;
    }) => {
      return AuthService.uploadDocuments(payload, params);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadDocuments;

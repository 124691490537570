import { borderRadius, spacing, timing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";

import IconLoadingComponent from "../IconLoading";
import { ButtonProps } from "./types";

export const Wrapper = styled.button<{
  $variant: ButtonProps["variant"];
  $small: ButtonProps["small"];
  $fullWidth: ButtonProps["fullWidth"];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${ifProp("$fullWidth", "100%", "fit-content")};
  text-align: center;
  height: ${ifProp("$small", "24px", "40px")};
  font-size: ${ifProp("$small", "0.9rem", "1rem")};
  font-family: inherit;
  font-weight: ${ifProp({ $variant: "outlined" }, 600, 400)};
  padding: ${ifProp("$small", `0 ${spacing(1.5)}px`, `0 ${spacing(4)}px`)};
  column-gap: ${ifProp("$small", spacing(0.5), spacing(1.25))}px;
  border-radius: ${ifProp(
    { $variant: "outlined" },
    `${borderRadius.medium}px`,
    `${borderRadius.normal}px`,
  )};
  border: 1px solid
    ${ifProp({ $variant: "outlined" }, theme("palette.silver"), "transparent")};
  transition: all ${timing.medium}ms;
  color: ${switchProp(
    "$variant",
    {
      secondary: "black",
      outlined: "black",
    },
    "white",
  )};
  background: ${switchProp(
    "$variant",
    {
      primary: theme("primary.bg"),
      secondary: theme("secondary.bg"),
      tertiary: theme("tertiary.bg"),
      outlined: theme("palette.white"),
      success: theme("success.primary"),
      warning: theme("warning.primary"),
      error: theme("error.primary"),
    },
    theme("primary.bg"),
  )};

  &:hover {
    color: ${switchProp("$variant", {
      outlined: theme("palette.white"),
    })};
    border: 1px solid
      ${ifProp({ $variant: "outlined" }, theme("palette.ocean"), "transparent")};
    background: ${switchProp(
      "$variant",
      {
        primary: theme("tertiary.bg"),
        secondary: theme("secondary.hover"),
        tertiary: theme("tertiary.hover"),
        outlined: theme("palette.ocean"),
        success: theme("palette.seaGreen"),
        warning: theme("palette.buddhaGold"),
        error: theme("palette.redBerry"),
      },
      theme("tertiary.bg"),
    )};
  }

  &:disabled {
    user-select: none;
    color: ${theme("disabled.text")};
    background: ${theme("disabled.bg")};
  }
`;

export const IconLoading = styled(IconLoadingComponent)`
  margin-right: ${spacing(0.5)}px;
`;

import { useQuery } from "@tanstack/react-query";

import { PaymentService, PaymentStatus } from "../../services";

const INTERVAL_REFETCH = 2_000;

const useProcessPayment = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["payments", id, "process"],
    queryFn: () => PaymentService.process(id),
    refetchInterval: query => {
      const data = query.state.data;

      if (data?.paymentStatus === PaymentStatus.Progress) {
        return INTERVAL_REFETCH;
      }

      return false;
    },
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useProcessPayment;

import padEnd from "lodash/padEnd";

const toFixedPrecise = (value: number, precision: number) => {
  const [integer, decimal] = `${value}`.split(".");

  const fraction = decimal?.slice(0, precision);

  return [integer, padEnd(fraction, precision, "0")].join(".");
};

export default toFixedPrecise;

import {
  handleError,
  useAdminSupplierStatementFiles,
  useDeleteAdminSupplierStatementFile,
  useUploadAdminSupplierStatementFile,
} from "@hotelspoint/api";
import {
  Box,
  Form,
  FormAdapter,
  FormUploadButton,
  Table,
  TableLayout,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { createFormData } from "@hotelspoint/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import getColumns from "./AdminSupplierStatementsDocuments.columns";
import {
  defaultValues,
  validationSchema,
} from "./AdminSupplierStatementsDocuments.util";

interface AdmiSupplierStatementsDocumentsProps {
  id: number;
}

const AdmiSupplierStatementsDocuments = ({
  id,
}: AdmiSupplierStatementsDocumentsProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  const [targetName, setTargetName] = useState<string | null>(null);

  const [files, isLoading] = useAdminSupplierStatementFiles(id);
  const [deleteDocument, isDeleting] = useDeleteAdminSupplierStatementFile(id);
  const [uploadDocument, isUploading] = useUploadAdminSupplierStatementFile(id);

  const handleDelete = useCallback(
    async (name: string) => {
      try {
        setTargetName(name);
        await deleteDocument(name);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetName(null);
      }
    },
    [t, deleteDocument],
  );

  const handleUpload = useCallback(
    async (file: File | null) => {
      if (!file) return;

      try {
        const formData = createFormData(file);

        await uploadDocument(formData);

        toast.success(t("toast.adminSupplierStatement.uploadFile"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, uploadDocument],
  );

  const data = useMemo(() => files?.results ?? [], [files]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      token: token as string,
      id,
      targetName,
      handleDelete,
      isDeleting,
    });
  }, [t, token, id, targetName, handleDelete, isDeleting]);

  return (
    <TableLayout>
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        useReactTableProps={{
          state: {
            columnPinning: {
              left: ["id"],
              right: ["actions"],
            },
          },
        }}
      />
      <Form defaultValues={defaultValues} validationSchema={validationSchema}>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="file">
            {props => (
              <FormUploadButton
                {...props}
                onChange={handleUpload}
                isDisabled={isUploading}
                buttonProps={{
                  small: true,
                }}
              />
            )}
          </FormAdapter>
        </Box>
      </Form>
    </TableLayout>
  );
};

export default AdmiSupplierStatementsDocuments;

import { PlaceType } from "@hotelspoint/types";
import { formatDate, isDateBefore } from "@hotelspoint/utils";
import { IconCalendar, IconManFilled, IconUsers } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PlaceTypeIcon from "../PlaceTypeIcon";
import Tooltip from "../Tooltip";
import * as S from "./ActivitySearchCard.styled";

interface HotelSearchCardProps {
  type: PlaceType;
  name: string;
  dateIn: string;
  dateOut: string;
  adults: number;
  children: number;
}

const ActivitySearchCard = ({
  type,
  name,
  dateIn,
  dateOut,
  adults,
  children,
}: HotelSearchCardProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const isCheckInOutdated = useMemo(
    () => isDateBefore(new Date(dateIn), dateToday),
    [dateIn, dateToday],
  );

  const dateRange = useMemo(() => {
    return [dateIn, dateOut].map(value => formatDate(value)).join(" \u2014 ");
  }, [dateIn, dateOut]);

  return (
    <S.Wrapper>
      <S.Detail>
        <PlaceTypeIcon type={type} iconProps={{ size: 20 }} />
        <S.Title title={name}>{name}</S.Title>
      </S.Detail>
      <S.Detail>
        <IconCalendar size={20} />
        {isCheckInOutdated ? (
          <Tooltip placement="right">
            <Tooltip.Trigger>
              <s>{dateRange}</s>
            </Tooltip.Trigger>
            <Tooltip.Content>
              {t("components.activitySearchCard.fromToPeriod")}
            </Tooltip.Content>
          </Tooltip>
        ) : (
          <span>{dateRange}</span>
        )}
      </S.Detail>
      <S.Detail>
        <IconUsers size={20} />
        <span>{adults}</span>
        {children !== 0 && (
          <>
            <IconManFilled size={18} />
            <span>{children}</span>
          </>
        )}
      </S.Detail>
    </S.Wrapper>
  );
};

export default ActivitySearchCard;

import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
`;

export const Thumbnail = styled.div<{ $src: string | null }>`
  min-height: 180px;
  background: url(${prop("$src")}) no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: ${ifProp("$src", "pointer", "default")};

  @media ${mediaQuery.tablet} {
    min-width: 180px;
    height: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  row-gap: ${spacing(2)}px;
  padding: ${spacing(1.5)}px;

  @media ${mediaQuery.tablet} {
    row-gap: 0;
    padding: ${spacing(2)}px;
  }
`;

export const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleLink = styled(Link)`
  color: ${theme("palette.ocean")};
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
`;

export const Summary = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.1;
`;

export const BodyFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  row-gap: ${spacing(2)}px;

  @media ${mediaQuery.mobileM} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`;

export const Provider = styled.div<{ $src: string | null }>`
  min-height: 40px;
  background: url(${prop("$src")}) no-repeat;
  background-size: contain;
  background-position: center center;

  @media ${mediaQuery.tablet} {
    min-width: 100px;
    height: 100%;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${spacing(2)}px;
  padding: ${spacing(1.5)}px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15);
  border-width: 1px 0 0;

  @media ${mediaQuery.tablet} {
    width: 200px;
    row-gap: ${spacing(3)}px;
    padding: ${spacing(2)}px;
    border-width: 0 0 0 1px;
  }
`;

export const AsideGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const AsideHeader = styled.h6`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${pxToRem(16)};
  font-weight: 600;
`;

export const Emphasized = styled.em`
  margin-right: ${spacing(0.5)}px;
  font-size: ${pxToRem(16)};
`;

export const PriceWrapper = styled.span`
  font-size: ${pxToRem(20)};
  font-weight: 500;
`;

import {
  AdminAreaLocation,
  AdminAreaLocationUpdatePayload,
} from "@hotelspoint/api";
import { parseCoordinate } from "@hotelspoint/utils";
import { boolean, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: number(),
  name: string().required("adminAreaLocation.form.name.validation.required"),
  isActive: boolean().required(),
  latitude: number()
    .typeError("adminAreaLocation.form.latitude.validation.required")
    .min(-90, "adminAreaLocation.form.latitude.validation.min")
    .max(90, "adminAreaLocation.form.latitude.validation.max")
    .required("adminAreaLocation.form.latitude.validation.required"),
  longitude: number()
    .typeError("adminAreaLocation.form.longitude.validation.required")
    .min(-180, "adminAreaLocation.form.longitude.validation.min")
    .max(180, "adminAreaLocation.form.longitude.validation.max")
    .required("adminAreaLocation.form.longitude.validation.required"),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  id: undefined,
  name: "",
  isActive: undefined,
  latitude: undefined,
  longitude: undefined,
};

export const entity2Form = (entity: AdminAreaLocation): FormValues => ({
  id: entity.id,
  name: entity.name,
  isActive: entity.isActive,
  latitude: Number(parseCoordinate(entity.latitude)),
  longitude: Number(parseCoordinate(entity.longitude)),
});

export const form2Entity = (
  formValues: FormValues,
): AdminAreaLocationUpdatePayload => ({
  name: formValues.name,
  isActive: formValues.isActive,
  latitude: formValues.latitude,
  longitude: formValues.longitude,
});

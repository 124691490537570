import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const ModalWrapper = styled.div`
  max-width: 800px;
  width: 100vw;
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const Transfer = styled.h6`
  margin-bottom: ${spacing(1)}px;
  font-weight: 600;
`;

import { AdminAreaLocationCreatePayload } from "@hotelspoint/api";
import { parseCoordinate } from "@hotelspoint/utils";
import { InferType, number, object, string } from "yup";

export const validationSchema = object({
  name: string().required("adminAreaLocationsCreate.name.validation.required"),
  latitude: number()
    .typeError("adminAreaLocationsCreate.latitude.validation.required")
    .min(-90, "adminAreaLocationsCreate.latitude.validation.min")
    .max(90, "adminAreaLocationsCreate.latitude.validation.max")
    .required("adminAreaLocationsCreate.latitude.validation.required"),
  longitude: number()
    .typeError("adminAreaLocationsCreate.longitude.validation.required")
    .min(-180, "adminAreaLocationsCreate.longitude.validation.min")
    .max(180, "adminAreaLocationsCreate.longitude.validation.max")
    .required("adminAreaLocationsCreate.longitude.validation.required"),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  name: "",
  latitude: 0,
  longitude: 0,
};

export const form2Entity = (
  formValues: FormValues,
): AdminAreaLocationCreatePayload => ({
  isActive: true, // Area is enabled by default
  name: formValues.name,
  latitude: Number(parseCoordinate(formValues.latitude)),
  longitude: Number(parseCoordinate(formValues.longitude)),
});

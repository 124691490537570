import { createGlobalStyle } from "styled-components";
import { theme } from "styled-tools";

import fontFace from "./fonts";
import { timing } from "./theme";
import { mediaQuery, pxToRem } from "./utils";
import floatingUi from "./vendor/floatingUi";
import reactQuill from "./vendor/reactQuill";
import reactToastify from "./vendor/reactToastify";

const GlobalStyles = createGlobalStyle`
  ${fontFace};
  ${reactToastify};
  ${floatingUi};
  ${reactQuill};

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  body {
    width: 100%;
    font-size: ${theme("custom.body.fontSize")}px;
    line-height: 1.4;
    font-weight: normal;
    font-style: normal;
    font-family: 'TT Commons', Arial, sans-serif;
    color: ${theme("body.text")};
    background: ${theme("body.bg")};

    /* Experiment with font smoothing: https://fjolt.com/article/css-make-text-look-like-figma */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  #root {
    position: relative;
  }

  a {
    text-decoration: none;
    color: ${theme("link.text")};
    transition: all ease-in ${timing.semiFast}ms;

    &:hover {
      color: ${theme("link.hover")};
    }
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1;
    padding: 0;
    color: inherit;
    background: transparent;
    text-align: left;

    &:focus {
      outline: none;
    }

   &:disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  input {
    padding: 0;
    padding-inline: 0;
    padding-block: 0;
  }

  textarea {
    padding: 0;
  }

  input, textarea {
    &:focus {
      outline: none;
    }

    &:disabled {
      pointer-events: none;
    }

    &:not([type='checkbox']):not([type='radio']) {
      &:read-only {
        cursor: not-allowed;
      }
    }
  }

  /* Remove the controls for input type=number */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
  }

  p {
    overflow-wrap: break-word;
    margin-block-start: 0;
    margin-block-end: 0;
  }


  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
    margin: 0;
    overflow-wrap: break-word;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  h4, h5, h6 {
    font-weight: 500;
  }

  h1 {
    font-size: ${pxToRem(32)};

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(42)};
    }
  }

  h2 {
    font-size: ${pxToRem(28)};

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(34)};
    }
  }

  h3 {
    font-size: ${pxToRem(24)};

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(28)};
    }
  }

  h4 {
    font-size: ${pxToRem(20)};

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(22)};
    }
  }

  h5 {
    font-size: ${pxToRem(18)};

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(20)};
    }
  }

  h6 {
    font-size: 1rem;

    @media ${mediaQuery.tablet} {
      font-size: ${pxToRem(18)};
    }
  }

  ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  b, strong {
    font-weight: 600;
  }

  em {
    font-size: 0.95rem;
  }

  hr {
    width: 100%;
    border: none;
    margin-block-start: 0;
    margin-block-end: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.15);
  }

  svg {
    vertical-align: middle;
    min-width: fit-content;
  }
`;

export default GlobalStyles;

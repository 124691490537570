import { InferType, object, string } from "yup";

export const validationSchema = object({
  sessions: string(),
  languages: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  sessions: undefined,
  languages: undefined,
};

import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(1)}px;
  background-color: ${theme("palette.whiteSand")};
  padding: ${spacing(1.5)}px ${spacing(2.5)}px;
  border-radius: ${borderRadius.medium}px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  font-size: ${pxToRem(12)};
`;

export const TicketPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(4)}px;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(18)};
  line-height: 1;
`;

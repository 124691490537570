import {
  Box,
  Container,
  Flex,
  HeaderImage,
  PageWrapper,
  Tooltip,
  TransferSearchCard,
} from "@hotelspoint/components";
import {
  RecentTransferSearchQuery,
  useRecentTransferSearchesStore,
} from "@hotelspoint/store";
import {
  formatDateIso,
  isDateBefore,
  useDocumentTitle,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import backgroundImage from "./assets/backgroundSearchForm.jpg";
import * as S from "./SearchTransfers.styled";
import SearchTransfersForm from "./SearchTransfersForm";
import SearchTransfersResults from "./SearchTransfersResults";
import useTransferSearchParams from "./useTransferSearchParams";

const STUB_CHECK_OUT_ADVANCE = 3;

const SearchTransfers = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransfers.pageTitle"));

  const [initialSearch, setInitialSearch] = useState<any>(undefined);

  const [query, setQuery] = useTransferSearchParams();

  const recentSearches = useRecentTransferSearchesStore(state => state.queries);

  const dateToday = useMemo(() => new Date(), []);
  const dateInThreeDays = useMemo(() => {
    const value = new Date();
    value.setDate(value.getDate() + STUB_CHECK_OUT_ADVANCE);

    return value;
  }, []);

  // Populate the recent searches with stub data for the suggestions
  const recentSearchOptions = useMemo(() => {
    return recentSearches.map(query => ({
      ...query,
      outboundDate: query.outboundDate || formatDateIso(dateToday),
      returnDate: query.returnDate || formatDateIso(dateInThreeDays),
    }));
  }, [recentSearches, dateToday, dateInThreeDays]);

  const handleRecentSearch = useCallback(
    (query: RecentTransferSearchQuery) => {
      const isOutboundDateOutdated = isDateBefore(
        new Date(query.outboundDate),
        dateToday,
      );

      // Outdated queries are partially loaded into the form as initial values
      if (isOutboundDateOutdated) {
        setInitialSearch({
          tripType: query.type,
          pickUp: query.meta.pickUpPlaceName,
          dropOff: query.meta.dropOffPlaceName,
          pickUpPlace: {
            id: query.pickUp.placeId,
            type: query.pickUp.placeType,
          },
          dropOffPlace: {
            id: query.dropOff.placeId,
            type: query.dropOff.placeType,
          },
          travelers: {
            adults: query.adults,
            children: query.children,
          },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
      } else {
        setQuery(omit(query, "meta"));
      }
    },
    [dateToday, setQuery],
  );

  if (
    query.pickUp?.placeId !== undefined &&
    query.pickUp?.placeType !== undefined &&
    query.dropOff?.placeId !== undefined &&
    query.dropOff?.placeType !== undefined
  ) {
    return <SearchTransfersResults />;
  }

  return (
    <PageWrapper>
      <HeaderImage src={backgroundImage}>
        <Container>
          <S.HeaderWrapper>
            <S.HeaderCard>
              <SearchTransfersForm initialValues={initialSearch} />
            </S.HeaderCard>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
      {recentSearches.length !== 0 && (
        <Container>
          <div style={{ marginTop: 48 }}>
            <Tooltip>
              <Tooltip.Trigger>
                <h4 style={{ marginBottom: 16 }}>
                  <span>{t("searchTransfers.recentSearches.title")}</span>
                </h4>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {t("searchTransfers.recentSearches.tooltip")}
              </Tooltip.Content>
            </Tooltip>
            <Flex mx={[0, -1, -1, -1]} my={[-1, -1, -1, -1]}>
              {recentSearchOptions.map((query, index) => (
                <Box
                  key={`recent-search-${index}`}
                  width={[1, 1 / 2, 1 / 2, 1 / 3]}
                  px={[0, 1, 1, 1]}
                  py={[1, 1, 1, 1]}
                >
                  <button
                    type="button"
                    onClick={() => handleRecentSearch(query)}
                    style={{ width: "100%" }}
                  >
                    <TransferSearchCard
                      type={query.type}
                      pickUp={{
                        name: query.meta.pickUpPlaceName,
                        type: query.pickUp.placeType,
                      }}
                      dropOff={{
                        name: query.meta.dropOffPlaceName,
                        type: query.dropOff.placeType,
                      }}
                      outboundDate={query.outboundDate}
                      returnDate={query.returnDate}
                      adults={query.adults}
                      children={query.children.length}
                    />
                  </button>
                </Box>
              ))}
            </Flex>
          </div>
        </Container>
      )}
    </PageWrapper>
  );
};

export default SearchTransfers;

import { Paper } from "@hotelspoint/components";
import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Card = styled(Paper)`
  padding: ${spacing(2)}px;
`;

export const Title = styled.h4`
  margin-bottom: ${spacing(2)}px;
  font-weight: 700;
`;

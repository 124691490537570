import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export default styled.div`
  padding-top: ${spacing(3)}px;

  @media ${mediaQuery.tablet} {
    padding-top: ${spacing(6)}px;
  }
`;

import {
  TransferSearchPayload,
  useTransferAvailabilitySearch,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  LoaderBlock,
  MarkupPrice,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  Price,
} from "@hotelspoint/components";
import theme from "@hotelspoint/theme";
import {
  getImagePathFromCDN,
  getTransferTypeName,
  ImageType,
  useDocumentTitle,
} from "@hotelspoint/utils";
import {
  IconCar,
  IconCircleCheckFilled,
  IconSearch,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import SearchTransfersForm from "../SearchTransfersResults/SearchTransfersForm";
import TransferContent from "../TransferContent";
import useTransferSearchParams from "../useTransferSearchParams";
import thumbPlaceholder from "./assets/thumbPlaceholder.jpg";
import * as S from "./SearchTransferPage.styled";

interface SearchTransferPageParams extends Record<string, string> {
  id: string;
}

const SearchTransferPage = () => {
  const { t } = useTranslation();

  useDocumentTitle(t("searchTransferPage.pageTitle"));

  const navigate = useNavigate();
  const { id } = useParams<SearchTransferPageParams>();
  const transferId = String(id);

  const [query] = useTransferSearchParams();

  const [search, isLoadingSearch, { refetch }] = useTransferAvailabilitySearch(
    query as TransferSearchPayload,
  );

  const selectedTransfer = search?.results.find(
    transfer => String(transfer.id) === transferId,
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchTransfers.pageTitle"),
        href: "/search/transfers",
        icon: () => <IconCar size={18} />,
      },
      {
        title: t("searchTransfersResults.search"),
        icon: () => <IconSearch size={18} />,
      },
    ],
    [t],
  );

  if (!selectedTransfer) {
    return <LoaderBlock />;
  }

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.Wrapper>
                <SearchTransfersForm refetch={refetch} />
              </S.Wrapper>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <S.Wrapper>
                <S.WrapperHeader>
                  <S.WrapperDetails>
                    <S.Title>{selectedTransfer.name}</S.Title>
                    {selectedTransfer.vehicle}
                    <S.Passengers>
                      <IconUsersGroup size={16} />
                      <span>
                        {t("searchTransferPage.transferDetails.maxPassengers", {
                          count: selectedTransfer.maxPassengers,
                        })}
                      </span>
                    </S.Passengers>
                    <S.Provider>{selectedTransfer.provider}</S.Provider>
                  </S.WrapperDetails>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      $src={
                        selectedTransfer.image
                          ? getImagePathFromCDN(
                              selectedTransfer.image as string,
                              ImageType.Transfer,
                            )
                          : thumbPlaceholder
                      }
                    />
                  </S.ThumbnailWrapper>
                </S.WrapperHeader>
                <S.Divider />
                <TransferContent
                  transfer={selectedTransfer}
                  isLoading={isLoadingSearch}
                />
                <S.Divider />
                <S.Footer>
                  <div>
                    <S.FooterDetails>
                      <S.FooterHeader>
                        {t(getTransferTypeName(selectedTransfer.type))}
                      </S.FooterHeader>
                      <S.Item>
                        <IconCircleCheckFilled
                          size={16}
                          style={{ color: theme.palette.eucalyptus }}
                        />
                        <span>
                          {t(
                            "searchTransferPage.transferDetails.modificationTime",
                            {
                              hours: selectedTransfer.modificationTime,
                            },
                          )}
                        </span>
                        <IconCircleCheckFilled
                          size={16}
                          style={{ color: theme.palette.eucalyptus }}
                        />
                        <span>
                          {t(
                            "searchTransferPage.transferDetails.cancellationTime",
                            {
                              hours: selectedTransfer.cancellationTime,
                            },
                          )}
                        </span>
                      </S.Item>
                    </S.FooterDetails>
                    <S.PriceWrapper>
                      {t("searchTransferPage.transferDetails.totalPrice")}
                      <MarkupPrice
                        value={{
                          total: selectedTransfer.price,
                          net: selectedTransfer.priceNet,
                        }}
                      >
                        <S.PriceWrapper>
                          <Price value={selectedTransfer.priceNet} />
                        </S.PriceWrapper>
                      </MarkupPrice>
                    </S.PriceWrapper>
                  </div>
                  <div>
                    <Button
                      variant="tertiary"
                      fullWidth={true}
                      onClick={() => navigate(`/search/transfers/${id}/book`)}
                    >
                      {t("searchTransferPage.transferDetails.book")}
                    </Button>
                  </div>
                </S.Footer>
              </S.Wrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransferPage;

import { Paper } from "@hotelspoint/components";
import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;

  h6 {
    font-weight: 600;
  }
`;

export const Title = styled.h5`
  margin-bottom: ${spacing(2)}px;
  font-weight: 700;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${spacing(2)}px;
  row-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(3)}px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  line-height: 1;
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${spacing(1)}px;

  @media ${mediaQuery.desktop} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`;

export const TicketList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(3)}px;
`;

export const TicketListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(16)};
  font-weight: 600;
`;

export const PriceTitle = styled.p`
  font-weight: 600;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(20)};
`;

import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import Carousel from "../Carousel";
import Dialog from "../Dialog";
import Modal from "../Modal";

interface GalleryOverlayProps {
  isOpen: boolean;
  images: string[];
  imageType: ImageType;
  initialSlideIndex: number;
  onClose: () => void;
}

const imageStyles = {
  height: 560,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "contain",
  borderRadius: "12px",
};

const GalleryOverlay = ({
  isOpen,
  images,
  imageType,
  initialSlideIndex,
  onClose,
}: GalleryOverlayProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <div style={{ maxWidth: 800, width: "100vw" }}>
          <Modal title={t("components.galleryOverlay.title")} onClose={onClose}>
            <Carousel initialSlide={initialSlideIndex}>
              {images?.map((img, index) => (
                <div key={index}>
                  <div
                    style={{
                      ...imageStyles,
                      backgroundImage: `url(${getImagePathFromCDN(
                        img,
                        imageType,
                      )})`,
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </Modal>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default GalleryOverlay;

import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp } from "styled-tools";

export interface FormLayoutProps {
  $small?: boolean;
}

export default styled.div<FormLayoutProps>`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1.5)}px;
  width: 100%;
  max-width: 100%;

  @media ${mediaQuery.tablet} {
    max-width: ${ifProp("$small", "380px")};
  }
`;

import { GalleryOverlay } from "@hotelspoint/components";
import {
  Hotel,
  SearchHotelsResultsModalState,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { ImageType } from "@hotelspoint/utils";

import HotelDetailsModal from "../HotelDetailsModal";

interface HotelDetailsGalleryModalProps {
  hotel: Hotel | undefined;
  activeModal: SearchHotelsResultsModalState | undefined;
  setActiveModal: (modal: SearchHotelsResultsModalState | undefined) => void;
  initialGalleryIndex?: number;
  setInitialGalleryIndex?: (index: number) => void;
  isLoading?: boolean;
}

const HotelDetailsGalleryModal = ({
  hotel,
  activeModal,
  setActiveModal,
  initialGalleryIndex = 0,
  setInitialGalleryIndex,
  isLoading = false,
}: HotelDetailsGalleryModalProps) => {
  const handleImageClick = (index: number) => {
    setInitialGalleryIndex && setInitialGalleryIndex(index);
    setActiveModal({
      id: hotel?.id,
      type: SearchHotelsResultsModalType.Gallery,
    });
  };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={
          activeModal?.type === SearchHotelsResultsModalType.Gallery &&
          hotel?.images?.length !== 0
        }
        images={hotel?.images ?? []}
        imageType={ImageType.Hotel}
        initialSlideIndex={initialGalleryIndex}
        onClose={handleCloseModal}
      />
      <HotelDetailsModal
        isOpen={activeModal?.type === SearchHotelsResultsModalType.Details}
        hotel={hotel}
        isLoading={isLoading}
        handleImageClick={handleImageClick}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default HotelDetailsGalleryModal;

import {
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useActivitySearchParams = () => {
  const [query, setQuery] = useQueryParams({
    placeId: NumberParam,
    placeType: StringParam,
    dateIn: StringParam,
    dateOut: StringParam,
    adults: NumberParam,
    children: JsonParam,
    searchId: NumberParam,
    useCache: BooleanParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useActivitySearchParams;

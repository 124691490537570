import { PlaceType, TransferTripType } from "@hotelspoint/types";
import {
  formatDateTimeHyphenUtc,
  getTransferTripTypeName,
  isDateBefore,
} from "@hotelspoint/utils";
import {
  IconManFilled,
  IconPlaneArrival,
  IconPlaneDeparture,
  IconUsers,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { Chip } from "../../elements";
import PlaceTypeIcon from "../PlaceTypeIcon";
import Tooltip from "../Tooltip";
import * as S from "./TransferSearchCard.styled";

interface TransferSearchCardProps {
  type: TransferTripType;
  pickUp: {
    name: string;
    type: PlaceType;
  };
  dropOff: {
    name: string;
    type: PlaceType;
  };
  outboundDate: string;
  returnDate?: string;
  adults: number;
  children: number;
}

const TransferSearchCard = ({
  type,
  pickUp,
  dropOff,
  outboundDate,
  returnDate,
  adults,
  children,
}: TransferSearchCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const isOutboundDateOutdated = useMemo(
    () => isDateBefore(new Date(outboundDate), dateToday),
    [outboundDate, dateToday],
  );

  return (
    <S.Wrapper>
      <S.WrapperHeader>
        <S.Detail>
          <IconUsers size={20} />
          <span>{adults}</span>
          {children !== 0 && (
            <>
              <IconManFilled size={18} />
              <span>{children}</span>
            </>
          )}
        </S.Detail>
        <Chip $background={theme.primary.bg}>
          {t(getTransferTripTypeName(type))}
        </Chip>
      </S.WrapperHeader>
      <S.Detail>
        <PlaceTypeIcon type={pickUp.type} iconProps={{ size: 20 }} />
        <S.Title title={pickUp.name}>{pickUp.name}</S.Title>
      </S.Detail>
      <S.Detail>
        <PlaceTypeIcon type={dropOff.type} iconProps={{ size: 20 }} />
        <S.Title title={dropOff.name}>{dropOff.name}</S.Title>
      </S.Detail>
      <S.DateWrapper>
        <S.Detail>
          <IconPlaneArrival size={20} />
          {isOutboundDateOutdated ? (
            <Tooltip placement="right">
              <Tooltip.Trigger>
                <S.Detail>
                  <s>{formatDateTimeHyphenUtc(outboundDate)}</s>
                </S.Detail>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {t("components.transferSearchCard.outboundDateOutdated")}
              </Tooltip.Content>
            </Tooltip>
          ) : (
            <>
              <span>{formatDateTimeHyphenUtc(outboundDate)}</span>
            </>
          )}
        </S.Detail>
        {type === TransferTripType.RoundTrip && (
          <S.Detail>
            <IconPlaneDeparture size={20} />
            {isOutboundDateOutdated ? (
              <Tooltip placement="right">
                <Tooltip.Trigger>
                  <S.Detail>
                    <s>{formatDateTimeHyphenUtc(returnDate as string)}</s>
                  </S.Detail>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  {t("components.transferSearchCard.outboundDateOutdated")}
                </Tooltip.Content>
              </Tooltip>
            ) : (
              <>
                <span>{formatDateTimeHyphenUtc(returnDate as string)}</span>
              </>
            )}
          </S.Detail>
        )}
      </S.DateWrapper>
    </S.Wrapper>
  );
};

export default TransferSearchCard;

import "mapbox-gl/dist/mapbox-gl.css";

import { MapProps } from "react-map-gl";

import Map from "./Map";

const StaticMap = ({ children, ...mapProps }: Partial<MapProps>) => (
  <Map
    interactive={false}
    mapStyle="mapbox://styles/mapbox/streets-v12"
    style={{ height: 420 }}
    {...mapProps}
  >
    {children}
  </Map>
);

export default StaticMap;

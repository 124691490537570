import {
  useActivityDetails,
  useActivityOptions,
  useActivitySearchInfo,
} from "@hotelspoint/api";
import { Button, MarkupPrice, Price } from "@hotelspoint/components";
import {
  SearchActivitiesResultsModalState,
  SearchActivitiesResultsModalType,
} from "@hotelspoint/types";
import {
  IconCalendar,
  IconCalendarX,
  IconClock,
  IconLanguage,
  IconMapPin,
  IconTagFilled,
  IconTicket,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ActivityDetailsGalleryModal from "../../SearchActivitiesResults/ActivityDetailsGalleryModal";
import { calculateTotalPrice } from "../SearchActivityBook.util";
import useSearchActivityBookParams from "../useSearchActivityBookParams";
import * as S from "./SearchActivityBookSidebar.styled";

interface SearchActivityBookSidebarParams extends Record<string, string> {
  id: string;
}

const SearchActivityBookSidebar = () => {
  const { t } = useTranslation();

  const { id } = useParams<SearchActivityBookSidebarParams>();
  const activityId = Number(id);

  const [{ searchId, date, session, languages, option, tickets }] =
    useSearchActivityBookParams();

  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  const [activeModal, setActiveModal] = useState<
    SearchActivitiesResultsModalState | undefined
  >(undefined);

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    searchId as number,
    activityId,
  );

  const [searchInfo, isLoadingSearchInfo] = useActivitySearchInfo(
    searchId as number,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId as number,
    id: activityId,
    date: date as string,
  });

  const selectedOption = useMemo(() => {
    return activityOptions?.options.find(val => val.key === option);
  }, [activityOptions?.options, option]);

  const totalPrice = useMemo(() => {
    return calculateTotalPrice(selectedOption, tickets);
  }, [selectedOption, tickets]);

  const selectedTicketsList = useMemo(() => {
    return tickets?.split(",").map(ticket => {
      const [key, numTickets] = ticket.split(":").map(Number);
      const item = selectedOption?.prices.find(d => d.key === key);

      return {
        numTicket: numTickets,
        priceNet: item ? item.priceNet : 0,
      };
    });
  }, [selectedOption, tickets]);

  return (
    <div>
      <ActivityDetailsGalleryModal
        activity={activityDetails}
        isLoading={isLoadingActivityDetails}
        activeModal={activeModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
        setActiveModal={setActiveModal}
      />
      <S.Card>
        <S.Title>
          {isLoadingActivityDetails ? (
            <Skeleton />
          ) : (
            activityDetails?.activity.name
          )}
        </S.Title>
        <S.List>
          <S.ListItem>
            {isLoadingSearchInfo ? (
              <Skeleton />
            ) : (
              <>
                <IconMapPin size={14} />
                {searchInfo?.search.place.name}
              </>
            )}
          </S.ListItem>
          {session && (
            <S.ListItem>
              <IconClock size={14} />
              {session}
            </S.ListItem>
          )}
          {languages && (
            <S.ListItem>
              <IconLanguage size={14} />
              {languages}
            </S.ListItem>
          )}
          <S.ListItem>
            <IconCalendar size={14} />
            {date}
          </S.ListItem>
          {!isLoadingActivityOptions && selectedOption?.name && (
            <S.ListItem>
              <IconTicket size={14} />
              {selectedOption.name}
            </S.ListItem>
          )}
        </S.List>
        <div>
          {isLoadingActivityDetails ? (
            <Skeleton width={150} height={40} />
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                setActiveModal({
                  id: activityDetails?.activity.id as number,
                  type: SearchActivitiesResultsModalType.Details,
                })
              }
            >
              {t("searchActivityBook.sidebar.activityDetails")}
            </Button>
          )}
        </div>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchActivityBook.sidebar.cancelationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        {isLoadingActivityOptions ? (
          <Skeleton count={1} />
        ) : selectedOption?.isRefundable ? (
          <p>
            {t(
              "searchActivityBook.sidebar.cancelationPolicy.freeCancelationUntil",
              {
                date: selectedOption?.deadline,
              },
            )}
          </p>
        ) : (
          <p>
            {t("searchActivityBook.sidebar.cancelationPolicy.nonRefundable")}
          </p>
        )}
      </S.Card>
      <S.HeadlineWrapper>
        <IconTagFilled size={18} />
        <h6>{t("searchActivityBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <p>{t("searchActivityBook.sidebar.priceBreakdown.ticketPrice")}</p>
          <S.TicketList>
            {selectedTicketsList?.map((ticket, index) => (
              <S.TicketListItem key={index}>
                <p>{ticket.numTicket}x</p>
                <Price value={ticket.priceNet} />
              </S.TicketListItem>
            ))}
          </S.TicketList>
        </S.Flex>
        <S.Flex>
          <S.PriceTitle>
            {t("searchActivityBook.sidebar.totalPrice")}
          </S.PriceTitle>
          <MarkupPrice
            value={{
              total: totalPrice.totalPrice,
              net: totalPrice.totalPriceNet,
            }}
          >
            <S.Price>
              <Price value={totalPrice.totalPriceNet} />
            </S.Price>
          </MarkupPrice>
        </S.Flex>
      </S.Card>
    </div>
  );
};

export default SearchActivityBookSidebar;

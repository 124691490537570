import { RateType } from "@hotelspoint/types";

import { DateType, daysBetween, isSameDay } from "../date";

export const getRateTypeName = (type: RateType) => {
  switch (type) {
    case RateType.Refundable:
      return "rateType.refundable";

    case RateType.NonRefundable:
      return "rateType.nonRefundable";

    case RateType.NotAvailable:
      return "rateType.notAvailable";

    case RateType.Unknown:
    default:
      return "rateType.unknown";
  }
};

export const getNightsCount = (checkIn: DateType, checkOut: DateType) => {
  if (isSameDay(checkIn, checkOut)) {
    return 1;
  }

  return daysBetween({
    from: checkIn,
    to: checkOut,
  });
};

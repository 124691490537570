import {
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import SearchActivityBookContent from "./SearchActivityBookContent";
import SearchActivityBookSidebar from "./SearchActivityBookSidebar";

const SearchActivityBook = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchActivityBook.pageTitle"));

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <PageSplitLayout wide>
            <PageSplitLayout.Sidebar>
              <SearchActivityBookSidebar />
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchActivityBookContent />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchActivityBook;

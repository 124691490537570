import {
  Box,
  Container,
  Flex,
  HeaderImage,
  HotelSearchCard,
  PageWrapper,
  Tooltip,
} from "@hotelspoint/components";
import {
  RecentHotelSearchQuery,
  useRecentHotelSearchesStore,
  useUserAgencyStore,
} from "@hotelspoint/store";
import { PlaceType } from "@hotelspoint/types";
import {
  formatDateIso,
  isDateBefore,
  randomIntFromInterval,
  useDocumentTitle,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import sumBy from "lodash/sumBy";
import qs from "query-string";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import headerImages from "./headerImages";
import * as S from "./SearchHotels.styled";
import SearchHotelsForm from "./SearchHotelsForm";
import SearchHotelsResults from "./SearchHotelsResults";
import useHotelSearchParams from "./useHotelSearchParams";

const STUB_CHECK_OUT_ADVANCE = 3;

const SearchHotels = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchHotels.pageTitle"));

  const navigate = useNavigate();

  const [initialSearch, setInitialSearch] = useState<any>(undefined);

  const [query, setQuery] = useHotelSearchParams();

  const agency = useUserAgencyStore(state => state.agency);
  const recentSearches = useRecentHotelSearchesStore(state => state.queries);

  const dateToday = useMemo(() => new Date(), []);
  const dateInThreeDays = useMemo(() => {
    const value = new Date();
    value.setDate(value.getDate() + STUB_CHECK_OUT_ADVANCE);

    return value;
  }, []);

  // Populate the recent searches with stub data for the suggestions
  const recentSearchOptions = useMemo(() => {
    return recentSearches.map(query => ({
      ...query,
      checkIn: query.checkIn || formatDateIso(dateToday),
      checkOut: query.checkOut || formatDateIso(dateInThreeDays),
      nationality: `${agency?.countryCode}`.toLowerCase(),
    }));
  }, [recentSearches, dateToday, dateInThreeDays, agency]);

  // Randomize an integer for the header image
  const randomInteger = useMemo(() => {
    return randomIntFromInterval(0, headerImages.length - 1);
  }, []);

  const headerImage = useMemo(
    () => headerImages[randomInteger],
    [randomInteger],
  );

  const handleRecentSearch = useCallback(
    (query: RecentHotelSearchQuery) => {
      const isCheckInOutdated = isDateBefore(
        new Date(query.checkIn),
        dateToday,
      );

      // Outdated queries are partially loaded into the form as initial values
      if (isCheckInOutdated) {
        setInitialSearch({
          search: query.meta.placeName,
          rooms: query.rooms,
          place: {
            id: query.placeId,
            type: query.placeType,
          },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
      } else {
        // If the selected place is of type hotel, navigate to the hotel page
        if (query.placeType === PlaceType.Hotel) {
          const queryParams = omit(query, ["meta", "view"]);
          const search = qs.stringify({
            ...queryParams,
            rooms: JSON.stringify(query.rooms),
          });

          navigate({
            pathname: `/search/hotels/${query.placeId}`,
            search,
          });
        } else {
          setQuery(omit(query, "meta"));
        }
      }
    },
    [dateToday, navigate, setQuery],
  );

  if (query.placeId !== undefined && query.placeType !== undefined) {
    return <SearchHotelsResults />;
  }

  return (
    <PageWrapper>
      <HeaderImage src={headerImage}>
        <Container>
          <S.HeaderWrapper>
            <S.HeaderCard>
              <SearchHotelsForm initialValues={initialSearch} />
            </S.HeaderCard>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
      {recentSearches?.length !== 0 && (
        <Container>
          <div style={{ marginTop: 48 }}>
            <Tooltip>
              <Tooltip.Trigger>
                <h4 style={{ marginBottom: 16 }}>
                  <span>{t("searchHotels.recentSearches.title")}</span>
                </h4>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {t("searchHotels.recentSearches.tooltip")}
              </Tooltip.Content>
            </Tooltip>
            <Flex mx={[0, -1, -1, -1]} my={[-1, -1, -1, -1]}>
              {recentSearchOptions.map((query, index) => (
                <Box
                  key={`recent-search-${index}`}
                  width={[1, 1 / 2, 1 / 2, 1 / 3]}
                  px={[0, 1, 1, 1]}
                  py={[1, 1, 1, 1]}
                >
                  <button
                    type="button"
                    onClick={() => handleRecentSearch(query)}
                    style={{ width: "100%" }}
                  >
                    <HotelSearchCard
                      type={query.placeType}
                      name={query.meta.placeName}
                      checkIn={query.checkIn}
                      checkOut={query.checkOut}
                      adults={sumBy(query.rooms, "adults")}
                      children={sumBy(query.rooms, "children.length")}
                    />
                  </button>
                </Box>
              ))}
            </Flex>
          </div>
        </Container>
      )}
    </PageWrapper>
  );
};

export default SearchHotels;

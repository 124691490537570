import {
  handleError,
  PaymentCreatePayload,
  PaymentStatus,
  PaymentType,
  Reservation,
  ReservationStatus,
  useCancelReservation,
  useCreatePayment,
} from "@hotelspoint/api";
import {
  Button,
  Chip,
  ConfirmDialog,
  Dialog,
  Modal,
  Panel,
  PaymentOptions,
  StatusFlavour,
} from "@hotelspoint/components";
import {
  createForm,
  formatDate,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
} from "@hotelspoint/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as S from "./ReservationBaseInfo.styled";

interface ReservationBaseInfoProps {
  reservation: Reservation;
}

const ReservationBaseInfo = ({ reservation }: ReservationBaseInfoProps) => {
  const { t } = useTranslation();

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const [createPayment, isCreatingPayment] = useCreatePayment();
  const [cancelReservation, isCancelingReservation] = useCancelReservation(
    reservation.id,
  );

  const handlePayment = async (
    values: Omit<PaymentCreatePayload, "reservationId">,
  ) => {
    try {
      const payload = {
        ...values,
        reservationId: reservation.id,
      };

      const response = await createPayment(payload);

      // Create a form with the payment URL and params, and submit it
      if (response.paymentType === PaymentType.CreditCard) {
        const form = createForm(response.paymentUrl, response.paymentUrlParams);

        document.body.appendChild(form);

        form.submit();
      }

      setIsPaymentDialogOpen(false);
    } catch (err: any) {
      handleError({ t, error: err });
    }
  };

  const handleCancelReservation = useCallback(async () => {
    try {
      await cancelReservation();

      toast.success(t("toast.profileReservations.cancel"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, cancelReservation]);

  return (
    <>
      <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
        <Dialog.Content>
          <div style={{ width: 720 }}>
            <Modal
              title={t("profileReservation.actions.makePayment.modal.title")}
              onClose={() => setIsPaymentDialogOpen(false)}
            >
              <PaymentOptions
                amount={reservation?.itemData?.priceNet}
                onConfirm={handlePayment}
                onCancel={() => setIsPaymentDialogOpen(false)}
                isLoading={isCreatingPayment}
              />
            </Modal>
          </div>
        </Dialog.Content>
      </Dialog>
      <Panel title={t("profileReservation.information")}>
        <S.InformationWrapper>
          <S.InformationBlockWrapper>
            <S.InformationBlock>
              <S.InformationBlockRow>
                <p>{t("profileReservation.bookingStatus")}</p>
                <Chip $color={getReservationStatusColor(reservation?.status)}>
                  {t(getReservationStatusName(reservation?.status))}
                </Chip>
              </S.InformationBlockRow>
              <S.InformationBlockRow>
                <p>{t("profileReservation.deadline")}</p>
                <p>{formatDate(reservation?.dateDeadline)}</p>
              </S.InformationBlockRow>
            </S.InformationBlock>
            <S.Divider />
            <S.InformationBlock>
              <S.InformationBlockRow>
                <p>{t("profileReservation.paymentStatus")}</p>
                <Chip
                  $color={getPaymentStatusColor(reservation?.paymentStatus)}
                >
                  {t(getPaymentStatusName(reservation?.paymentStatus))}
                </Chip>
              </S.InformationBlockRow>
              <S.InformationBlockRow>
                <p>{t("profileReservation.paymentDeadline")}</p>
                <p>{formatDate(reservation?.dateDeadline)}</p>
                {/* when Dido is ready this should be change with payment deadline */}
              </S.InformationBlockRow>
            </S.InformationBlock>
          </S.InformationBlockWrapper>
          <S.InformationActionWrapper>
            <ConfirmDialog
              title={t(
                "profileReservation.actions.cancelReservation.modal.title",
              )}
              description={t(
                "profileReservation.actions.cancelReservation.modal.description",
              )}
              onConfirm={handleCancelReservation}
              isLoading={isCancelingReservation}
              status={StatusFlavour.Error}
            >
              <Button
                variant="error"
                isDisabled={
                  reservation.status === ReservationStatus.ConfirmedReservation
                }
              >
                {t("profileReservation.actions.cancelReservation.button")}
              </Button>
            </ConfirmDialog>
            <Button
              variant="success"
              onClick={() => setIsPaymentDialogOpen(true)}
              isDisabled={reservation.paymentStatus === PaymentStatus.Confirmed}
            >
              {t("profileReservation.actions.makePayment.button")}
            </Button>
          </S.InformationActionWrapper>
        </S.InformationWrapper>
      </Panel>
    </>
  );
};

export default ReservationBaseInfo;

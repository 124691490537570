import { useMutation } from "@tanstack/react-query";

import { PaymentService } from "../../services";

const useUploadPaymentFile = () => {
  const mutation = useMutation({
    mutationKey: ["payments", "upload_file"],
    mutationFn: (payload: FormData) => {
      return PaymentService.uploadFile(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadPaymentFile;

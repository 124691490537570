import { IconUpload } from "@tabler/icons-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import FileList from "../../composites/FileList";
import Button from "../../elements/Button";
import { ButtonProps } from "../../elements/Button/types";
import { FormControlInjectedProps } from "../FormControl";
import * as S from "./FormUploadButton.styled";

const ICON_SIZE = {
  SMALL: 14,
  DEFAULT: 18,
};

interface FormUploadButtonProps extends FormControlInjectedProps {
  value: File | null;
  onChange: (file: File | null) => void;
  isDisabled?: boolean;
  buttonProps?: Partial<ButtonProps>;
}

const FormUploadButton = ({
  value,
  onChange,
  isDisabled = false,
  buttonProps,
  ...inputProps
}: FormUploadButtonProps) => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReset = () => {
    toast.success(t("toast.removeFile"));

    onChange(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;

    onChange(file);
  };

  return (
    <div>
      <S.Wrapper>
        <Button
          variant="outlined"
          onClick={handleButtonClick}
          isDisabled={isDisabled}
          {...buttonProps}
        >
          <IconUpload
            size={buttonProps?.small ? ICON_SIZE.SMALL : ICON_SIZE.DEFAULT}
          />
          <span>{t("components.formUploadButton.upload")}</span>
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleChange}
          style={{ display: "none" }}
          {...inputProps}
        />
      </S.Wrapper>
      {value && (
        <S.FilesWrapper>
          <FileList value={[value]} onRemove={() => handleReset()} />
        </S.FilesWrapper>
      )}
    </div>
  );
};

export default FormUploadButton;

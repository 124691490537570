import { useActivityOptions, useActivitySearchInfo } from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  ImportantInfo,
  MarkupPrice,
  PassengerInfo,
  PassengerType,
  Price,
} from "@hotelspoint/components";
import {
  IconAddressBook,
  IconManFilled,
  IconUserFilled,
  IconUserQuestion,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ActivityContactsInfo from "../../../components/ActivityContactsInfo";
import { calculateTotalPrice } from "../../SearchActivityBook.util";
import useSearchActivityBookParams from "../../useSearchActivityBookParams";
import * as S from "./SearchActivityBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  getDynamicValidationSchema,
} from "./SearchActivityBookForm.util";

interface SearchActivityBookFormParams extends Record<string, string> {
  id: string;
}

const SearchActivityBookForm = () => {
  const { t } = useTranslation();
  const [{ searchId, date, option, tickets }] = useSearchActivityBookParams();

  const { id } = useParams<SearchActivityBookFormParams>();
  const activityId = Number(id);

  const [searchInfo, isLoadingSearchInfo] = useActivitySearchInfo(
    searchId as number,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId as number,
    id: activityId,
    date: date as string,
  });

  const selectedOption = useMemo(() => {
    return activityOptions?.options.find(val => val.key === option);
  }, [activityOptions?.options, option]);

  const totalPrice = useMemo(() => {
    return calculateTotalPrice(selectedOption, tickets);
  }, [selectedOption, tickets]);

  const formValues = useMemo(() => {
    if (!searchInfo) return defaultValues;
    return entity2Form(searchInfo.search.children);
  }, [searchInfo]);

  const validationSchema = useMemo(() => {
    if (!selectedOption) return;

    return getDynamicValidationSchema(selectedOption.questions);
  }, [selectedOption]);

  const isLoading = isLoadingSearchInfo || isLoadingActivityOptions;

  const onSubmit = (formValues: FormValues) => {
    console.log("form2Entity", form2Entity(formValues));
    console.log("book");
  };

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormLayout>
        <S.SectionTitle>
          <IconUserFilled size={18} />
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <span>
              {t("searchActivityBook.content.adult", {
                count: searchInfo?.search.adults,
              })}
            </span>
          )}
        </S.SectionTitle>
        {isLoading || !searchInfo ? (
          <PassengerInfo isLoading={isLoading} />
        ) : (
          Array.from({ length: searchInfo.search.adults }).map(
            (_, adultIndex) => {
              const name = `adults.${adultIndex}`;
              return (
                <PassengerInfo
                  key={adultIndex}
                  name={name}
                  passengerIndex={adultIndex}
                />
              );
            },
          )
        )}
        {searchInfo?.search.children.length !== 0 && (
          <>
            <S.SectionTitle>
              <IconManFilled size={18} />
              <span>{t("searchActivityBook.content.children")}</span>
            </S.SectionTitle>
            {searchInfo?.search.children.map((_, childIndex) => {
              const name = `children.${childIndex}`;
              return (
                <PassengerInfo
                  key={childIndex}
                  name={name}
                  type={PassengerType.Children}
                  passengerIndex={childIndex}
                />
              );
            })}
          </>
        )}
        <S.SectionTitle>
          <IconAddressBook size={18} />
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <span>{t("searchActivityBook.content.contacts")}</span>
          )}
        </S.SectionTitle>
        {isLoading ? (
          <PassengerInfo isLoading={isLoading} />
        ) : (
          <ActivityContactsInfo />
        )}
        {selectedOption?.questions.length !== 0 && (
          <>
            <S.SectionTitle>
              <IconUserQuestion size={18} />
              <span>{t("searchActivityBook.content.questions")}</span>
            </S.SectionTitle>
            {selectedOption?.questions.map((question, questionIndex) => (
              <FormAdapter
                key={questionIndex}
                name={question.code}
                label={question.text}
              >
                {props => <FormInput {...props} />}
              </FormAdapter>
            ))}
          </>
        )}
        <S.Divider />
        {selectedOption?.remarks.length !== 0 && (
          <ImportantInfo
            remarks={selectedOption?.remarks}
            isLoading={isLoading}
          />
        )}
        <S.FormFooter>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <S.FormPriceWrap>
              <p>{t("searchActivityBook.content.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: totalPrice.totalPrice,
                  net: totalPrice.totalPriceNet,
                }}
              >
                <S.PriceTotal>
                  <Price value={totalPrice.totalPriceNet} />
                </S.PriceTotal>
              </MarkupPrice>
            </S.FormPriceWrap>
          )}
          <FormContext<any>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchActivityBook.content.form.submit")}
              </Button>
            )}
          />
        </S.FormFooter>
      </FormLayout>
    </Form>
  );
};

export default SearchActivityBookForm;

import { ActivitySearchPayload, ActivityService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useActivityAvailabilitySearch = (params: ActivitySearchPayload) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["activity_availability", params],
    queryFn: () => ActivityService.search(params),
  });

  const queryInstance = {
    refetch,
  };

  const ret: [typeof data, boolean, typeof queryInstance] = [
    data,
    isLoading,
    queryInstance,
  ];

  return ret;
};

export default useActivityAvailabilitySearch;

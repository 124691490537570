import styled, { keyframes } from "styled-components";
import { theme } from "styled-tools";

const pulse = keyframes`
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
`;

const pin = keyframes`
  0%, 100% {
    transform: translateY(0) rotate(-45deg);
  }
  50% {
    transform: translateY(-20px) rotate(-45deg);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Pin = styled.div`
  z-index: 2;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: ${theme("secondary.bg")};
  animation-name: ${pin};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  transform: rotate(-45deg);

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 50%;
  }
`;

export const Pulse = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin: 0 auto;
  transform: rotateX(55deg);

  &:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    /* Minus values for the dimensions of the pulse element (-1px for the border) */
    top: -13px;
    left: -13px;
    animation: ${pulse} 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px ${theme("primary.bg")};
  }
`;

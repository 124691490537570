import { UserRole } from "@hotelspoint/types";
import { Navigate, Route } from "react-router-dom";

import AuthenticatedRoute from "./auth/routes/AuthenticatedRoute";
import NonAuthenticatedRoute from "./auth/routes/NonAuthenticatedRoute";
import UserRoleProtectedRoute from "./auth/routes/UserRoleProtectedRoute";
import RootLayout from "./layouts/RootLayout";
import About from "./routes/About";
import Admin from "./routes/Admin";
import AdminAgencies from "./routes/Admin/AdminAgencies";
import AdminAgency from "./routes/Admin/AdminAgencies/AdminAgency";
import AdminAgents from "./routes/Admin/AdminAgents";
import AdminAgent from "./routes/Admin/AdminAgents/AdminAgent";
import AdminCommissions from "./routes/Admin/AdminCommissions";
import AdminCommission from "./routes/Admin/AdminCommissions/AdminCommission";
import AdminCurrencyConfiguration from "./routes/Admin/AdminConfiguration/AdminCurrencyConfiguration";
import AdminMealConfiguration from "./routes/Admin/AdminConfiguration/AdminMealConfiguration/AdminMealConfiguration";
import AdminDashboard from "./routes/Admin/AdminDashboard";
import AdminInvoices from "./routes/Admin/AdminInvoices";
import AdminInvoice from "./routes/Admin/AdminInvoices/AdminInvoice/AdminInvoice";
import AdminAirportLocations from "./routes/Admin/AdminLocations/AdminAirportLocations";
import AdminAirportLocation from "./routes/Admin/AdminLocations/AdminAirportLocations/AdminAirportLocation";
import AdminAreaLocations from "./routes/Admin/AdminLocations/AdminAreaLocations";
import AdminAreaLocation from "./routes/Admin/AdminLocations/AdminAreaLocations/AdminAreaLocation";
import AdminAreaLocationsCreate from "./routes/Admin/AdminLocations/AdminAreaLocations/AdminAreaLocationsCreate";
import AdminCityLocations from "./routes/Admin/AdminLocations/AdminCityLocations";
import AdminCityLocation from "./routes/Admin/AdminLocations/AdminCityLocations/AdminCityLocation";
import AdminDestinationLocations from "./routes/Admin/AdminLocations/AdminDestinationLocations";
import AdminDestinationLocation from "./routes/Admin/AdminLocations/AdminDestinationLocations/AdminDestinationLocation";
import AdminMessages from "./routes/Admin/AdminMessages";
import AdminPayments from "./routes/Admin/AdminPayments";
import AdminPayment from "./routes/Admin/AdminPayments/AdminPayment";
import AdminActivityRequests from "./routes/Admin/AdminRequests/AdminActivityRequests";
import AdminHotelRequests from "./routes/Admin/AdminRequests/AdminHotelRequests";
import AdminPaymentRequests from "./routes/Admin/AdminRequests/AdminPaymentRequests";
import AdminTransferRequests from "./routes/Admin/AdminRequests/AdminTransferRequests";
import AdminReservations from "./routes/Admin/AdminReservations";
import AdminReservation from "./routes/Admin/AdminReservations/AdminReservation";
import AdminActivitySearches from "./routes/Admin/AdminSearches/AdminActivitySearches";
import AdminHotelSearches from "./routes/Admin/AdminSearches/AdminHotelSearches";
import AdminTransferSearches from "./routes/Admin/AdminSearches/AdminTransferSearches";
import AdminAgencyStatements from "./routes/Admin/AdminStatements/AdminAgencyStatements";
import AdminAgencyStatement from "./routes/Admin/AdminStatements/AdminAgencyStatements/AdminAgencyStatement";
import AdminAgencyStatementsCreate from "./routes/Admin/AdminStatements/AdminAgencyStatements/AdminAgencyStatementsCreate";
import AdminSupplierStatements from "./routes/Admin/AdminStatements/AdminSupplierStatements";
import AdminSupplierStatement from "./routes/Admin/AdminStatements/AdminSupplierStatements/AdminSupplierStatement";
import AdminSupplierStatementsCreate from "./routes/Admin/AdminStatements/AdminSupplierStatements/AdminSupplierStatementsCreate";
import ChangePassword from "./routes/ChangePassword";
import Home from "./routes/Home/Home";
import NotFound from "./routes/NotFound";
import PaymentComplete from "./routes/PaymentComplete";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Profile from "./routes/Profile";
import ProfileInvoices from "./routes/Profile/ProfileInvoices";
import ProfileOffers from "./routes/Profile/ProfileOffers";
import ProfileOffer from "./routes/Profile/ProfileOffers/ProfileOffer";
import ProfileOffersCreate from "./routes/Profile/ProfileOffers/ProfileOffersCreate";
import ProfilePayments from "./routes/Profile/ProfilePayments";
import ProfileReservations from "./routes/Profile/ProfileReservations";
import ProfileReservation from "./routes/Profile/ProfileReservations/ProfileReservation";
import ProfileSettings from "./routes/Profile/ProfileSettings";
import ProfileAgencySettings from "./routes/Profile/ProfileSettings/ProfileAgencySettings";
import ProfileAgentSettings from "./routes/Profile/ProfileSettings/ProfileAgentSettings";
import Register from "./routes/Register";
import RegisterConfirm from "./routes/RegisterConfirm";
import Search from "./routes/Search";
import SearchActivities from "./routes/Search/SearchActivities";
import SearchActivityBook from "./routes/Search/SearchActivities/SearchActivityBook";
import SearchActivityPage from "./routes/Search/SearchActivities/SearchActivityPage";
import SearchCarRentals from "./routes/Search/SearchCarRentals";
import SearchHotels from "./routes/Search/SearchHotels";
import SearchHotelBook from "./routes/Search/SearchHotels/SearchHotelBook";
import SearchHotelPage from "./routes/Search/SearchHotels/SearchHotelPage";
import SearchTransfers from "./routes/Search/SearchTransfers";
import SearchTransferBook from "./routes/Search/SearchTransfers/SearchTransferBook";
import SearchTransferPage from "./routes/Search/SearchTransfers/SearchTransferPage";
import TermsAndConditions from "./routes/TermsAndConditions";
import SentryRoutes from "./tools/SentryRoutes";

const Router = () => (
  <SentryRoutes>
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="login" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="terms" element={<TermsAndConditions />} />

      <Route element={<NonAuthenticatedRoute />}>
        <Route path="register" element={<Register />} />
        <Route path="confirm" element={<RegisterConfirm />} />
        <Route path="reset" element={<ChangePassword />} />
      </Route>

      <Route element={<AuthenticatedRoute />}>
        <Route path="search" element={<Search />}>
          <Route path="hotels">
            <Route index element={<SearchHotels />} />
            <Route path=":id">
              <Route index element={<SearchHotelPage />} />
              <Route path="book" element={<SearchHotelBook />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="transfers">
            <Route index element={<SearchTransfers />} />
            <Route path=":id">
              <Route index element={<SearchTransferPage />} />
              <Route path="book" element={<SearchTransferBook />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>

          <Route path="car-rentals" element={<SearchCarRentals />} />
          <Route path="activities">
            <Route index element={<SearchActivities />} />
            <Route path=":id">
              <Route index element={<SearchActivityPage />} />
              <Route path="book" element={<SearchActivityBook />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="payments/:id" element={<PaymentComplete />} />

        <Route path="profile" element={<Profile />}>
          <Route index element={<Navigate to="/profile/offers" replace />} />
          <Route path="offers">
            <Route index element={<ProfileOffers />} />
            <Route path="create" element={<ProfileOffersCreate />} />
            <Route path=":id" element={<ProfileOffer />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="bookings">
            <Route index element={<ProfileReservations />} />
            <Route path=":id" element={<ProfileReservation />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="invoices" element={<ProfileInvoices />} />
          <Route path="payments" element={<ProfilePayments />} />
          <Route path="settings" element={<ProfileSettings />}>
            <Route
              index
              element={<Navigate to="/profile/settings/agent" replace />}
            />
            <Route path="agent" element={<ProfileAgentSettings />} />
            <Route path="agency" element={<ProfileAgencySettings />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route
          element={
            <UserRoleProtectedRoute roles={[UserRole.SuperAdministrator]} />
          }
        >
          <Route path="admin" element={<Admin />}>
            <Route index element={<Navigate to="/admin/dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="agents">
              <Route index element={<AdminAgents />} />
              <Route path=":id" element={<AdminAgent />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="agencies">
              <Route index element={<AdminAgencies />} />
              <Route path=":id" element={<AdminAgency />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="invoices">
              <Route index element={<AdminInvoices />} />
              <Route path=":id" element={<AdminInvoice />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="bookings">
              <Route index element={<AdminReservations />} />
              <Route path=":id" element={<AdminReservation />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="messages" element={<AdminMessages />} />
            <Route path="confirmations" element={<NotFound />} />
            <Route path="payments">
              <Route index element={<AdminPayments />} />
              <Route path=":id" element={<AdminPayment />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="commissions">
              <Route index element={<AdminCommissions />} />
              <Route path=":id" element={<AdminCommission />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="statements">
              <Route
                index
                element={<Navigate to="/admin/statements/agencies" replace />}
              />
              <Route path="agencies">
                <Route index element={<AdminAgencyStatements />} />
                <Route
                  path="create"
                  element={<AdminAgencyStatementsCreate />}
                />
                <Route path=":id" element={<AdminAgencyStatement />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="suppliers">
                <Route index element={<AdminSupplierStatements />} />
                <Route
                  path="create"
                  element={<AdminSupplierStatementsCreate />}
                />
                <Route path=":id" element={<AdminSupplierStatement />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
            <Route path="searches">
              <Route
                index
                element={<Navigate to="/admin/searches/hotels" replace />}
              />
              <Route path="hotels" element={<AdminHotelSearches />} />
              <Route path="transfers" element={<AdminTransferSearches />} />
              <Route path="activities" element={<AdminActivitySearches />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="requests">
              <Route
                index
                element={<Navigate to="/admin/requests/hotels" replace />}
              />
              <Route path="hotels" element={<AdminHotelRequests />} />
              <Route path="transfers" element={<AdminTransferRequests />} />
              <Route path="activities" element={<AdminActivityRequests />} />
              <Route path="payments" element={<AdminPaymentRequests />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="locations">
              <Route
                index
                element={<Navigate to="/admin/locations/areas" replace />}
              />
              <Route path="areas">
                <Route index element={<AdminAreaLocations />} />
                <Route path="create" element={<AdminAreaLocationsCreate />} />
                <Route path=":id" element={<AdminAreaLocation />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="cities">
                <Route index element={<AdminCityLocations />} />
                <Route path=":id" element={<AdminCityLocation />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="airports">
                <Route index element={<AdminAirportLocations />} />
                <Route path=":id" element={<AdminAirportLocation />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="destinations">
                <Route index element={<AdminDestinationLocations />} />
                <Route path=":id" element={<AdminDestinationLocation />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="configuration">
              <Route
                index
                element={<Navigate to="/admin/configuration/meals" replace />}
              />
              <Route path="meals" element={<AdminMealConfiguration />} />
              <Route
                path="currencies"
                element={<AdminCurrencyConfiguration />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Route>

      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </SentryRoutes>
);

export default Router;

import { UserRole } from "@hotelspoint/types";

import useUserProfileStore from "../useUserProfileStore";

const useIsUserRole = (role: UserRole) => {
  const roleId = useUserProfileStore(state => state.profile?.roleId);

  return roleId && roleId == role;
};

export default useIsUserRole;

import { utc } from "@date-fns/utc";
import { format } from "date-fns";

import {
  FORMAT_DATE,
  FORMAT_DATE_DAY_MONTH_SHORT,
  FORMAT_DATE_ISO,
  FORMAT_DATE_READABLE,
  FORMAT_DATE_READABLE_FULL,
  FORMAT_DATE_TIME,
  FORMAT_DATE_TIME_HYPHEN,
  FORMAT_DATE_TIME_ISO,
  FORMAT_TIME,
} from "./constants";
import { DateType } from "./utils";

// 19.03.2023
export const formatDate = (date: DateType) => {
  return format(date, FORMAT_DATE);
};

// 2023-03-19
export const formatDateIso = (date: DateType) => {
  return format(date, FORMAT_DATE_ISO);
};

// 19 Mar, 2023
export const formatDateReadable = (date: DateType) => {
  return format(date, FORMAT_DATE_READABLE);
};

// 19 March 2023
export const formatDateReadableFull = (date: DateType) => {
  return format(date, FORMAT_DATE_READABLE_FULL);
};

// 19.03.2023 19:44
export const formatDateTime = (date: DateType) => {
  return format(date, FORMAT_DATE_TIME);
};

// 2023-03-19T19:44:12Z
export const formatDateTimeIso = (date: DateType) => {
  return format(date, FORMAT_DATE_TIME_ISO);
};

// 19 Mar
export const formatDateDayMonthShort = (date: DateType) => {
  return format(date, FORMAT_DATE_DAY_MONTH_SHORT);
};

// 12:30
export const formatTime = (date: DateType) => {
  return format(date, FORMAT_TIME);
};

// 12:30 - taking into account the UTC timezone
export const formatTimeUtc = (date: DateType) => {
  return format(date, FORMAT_TIME, { in: utc });
};

// 19.03.2023 - 19:44
export const formatDateTimeHyphenUtc = (date: DateType) => {
  return format(date, FORMAT_DATE_TIME_HYPHEN, { in: utc });
};

import { Message, MessageStatus } from "@hotelspoint/api";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDateTime,
  getMessagesPriorityColor,
  getMessagesPriorityName,
  getMessagesStatusColor,
  getMessagesStatusName,
  getMessageTypeName,
} from "@hotelspoint/utils";
import { IconBell } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import dropRight from "lodash/dropRight";
import last from "lodash/last";

import Chip from "../../elements/Chip";
import { TABLE_COLUMN_SIZE } from "../../table/constants";
import TableActions from "../../table/TableActions";
import * as S from "./ReservationMessages.styled";

const columnHelper = createColumnHelper<Message>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  isUpdating: boolean;
  handleOpenReadModal: (id: number) => void;
  handleOpenPriorityModal: (id: number) => void;
  handleUpdateStatus: (id: number, status: MessageStatus | null) => void;
  handleUpdateAlert: (id: number, state: boolean) => void;
}

const getColumns = ({
  t,
  targetId,
  isUpdating,
  handleOpenReadModal,
  handleOpenPriorityModal,
  handleUpdateStatus,
  handleUpdateAlert,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("components.reservationMessages.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
    cell: props => {
      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {props.getValue()}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("type", {
    header: t("components.reservationMessages.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const messageType = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {t(getMessageTypeName(messageType))}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.display({
    id: "subject",
    header: t("components.reservationMessages.columns.subject"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => {
      const { status, subject, isNew, isAlert } = props.row.original;

      return (
        <S.CellWrapper $active={isNew}>
          <div style={{ display: "flex", columnGap: 8 }}>
            {isAlert && (
              <IconBell size={18} style={{ color: CHIP_COLORS.RED }} />
            )}
            {status && (
              <Chip $color={getMessagesStatusColor(status)}>
                {t(getMessagesStatusName(status))}
              </Chip>
            )}
            <span>{subject}</span>
          </div>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("priority", {
    header: t("components.reservationMessages.columns.priority"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const priority = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          <Chip $color={getMessagesPriorityColor(priority)}>
            {t(getMessagesPriorityName(priority))}
          </Chip>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("components.reservationMessages.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => {
      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {formatDateTime(props.getValue())}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id, status, isAlert } = props.row.original;
      const isTarget = id === targetId;

      const statusOptions = [
        ...(status !== null
          ? [
              {
                id: "update_status_to_none",
                title: t(
                  "components.reservationMessages.columns.actions.status.noStatus",
                ),
                onClick: () => handleUpdateStatus(id, null),
              },
            ]
          : []),
        ...(status !== MessageStatus.InProgress
          ? [
              {
                id: "update_status_to_in_progress",
                title: t(
                  "components.reservationMessages.columns.actions.status.inProgress",
                ),
                onClick: () => handleUpdateStatus(id, MessageStatus.InProgress),
              },
            ]
          : []),
        ...(status !== MessageStatus.Pending
          ? [
              {
                id: "update_status_to_pending",
                title: t(
                  "components.reservationMessages.columns.actions.status.pending",
                ),
                onClick: () => handleUpdateStatus(id, MessageStatus.Pending),
              },
            ]
          : []),
        ...(status !== MessageStatus.Completed
          ? [
              {
                id: "update_status_to_completed",
                title: t(
                  "components.reservationMessages.columns.actions.status.completed",
                ),
                onClick: () => handleUpdateStatus(id, MessageStatus.Completed),
              },
            ]
          : []),
      ];

      return (
        <TableActions
          options={[
            {
              id: "read",
              title: t(
                "components.reservationMessages.columns.actions.read.button",
              ),
              onClick: () => handleOpenReadModal(id),
            },
            {
              id: "update_priority",
              title: t(
                "components.reservationMessages.columns.actions.priority.button",
              ),
              isSeparated: true,
              onClick: () => handleOpenPriorityModal(id),
            },
            // Always keep the status options separated from the other action items
            ...[
              ...dropRight(statusOptions),
              { ...(last(statusOptions) as any), isSeparated: true },
            ],
            {
              id: "update_alert",
              title: isAlert
                ? t(
                    "components.reservationMessages.columns.actions.alert.unset",
                  )
                : t("components.reservationMessages.columns.actions.alert.set"),
              onClick: () => handleUpdateAlert(id, !isAlert),
            },
          ]}
          isLoading={isTarget && isUpdating}
        />
      );
    },
  }),
];

export default getColumns;

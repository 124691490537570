const createForm = (action: string, params: Record<string, any>) => {
  const form = document.createElement("form");

  form.setAttribute("method", "POST");
  form.setAttribute("action", action);

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", params[key]);

      form.appendChild(input);
    }
  }

  return form;
};

export default createForm;

import {
  PaymentStatus,
  ReservationStatus,
  useProcessPayment,
} from "@hotelspoint/api";
import {
  Button,
  Container,
  getStatusIconComponent,
  Loader,
  PagePaper,
  PageWrapper,
  StatusFlavour,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

import * as S from "./PaymentComplete.styled";

interface PaymentCompleteParams extends Record<string, string> {
  id: string;
}

const PaymentComplete = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("paymentComplete.pageTitle"));

  const theme = useTheme();

  const { id } = useParams<PaymentCompleteParams>();
  const [response, isLoading] = useProcessPayment(Number(id));

  const { paymentStatus, reservationStatus, reservationId } = response || {};

  const iconProps = useMemo(() => {
    if (isLoading) return null;

    if (
      (reservationStatus !== ReservationStatus.PendingConfirmation &&
        reservationStatus !== ReservationStatus.ConfirmedReservation) ||
      (paymentStatus !== PaymentStatus.Reserved &&
        paymentStatus !== PaymentStatus.Confirmed)
    ) {
      return {
        Component: getStatusIconComponent(StatusFlavour.Error),
        color: theme.error.primary,
      };
    }

    return {
      Component: getStatusIconComponent(StatusFlavour.Success),
      color: theme.success.primary,
    };
  }, [isLoading, paymentStatus, reservationStatus, theme]);

  const title = useMemo(() => {
    if (
      reservationStatus !== ReservationStatus.PendingConfirmation &&
      reservationStatus !== ReservationStatus.ConfirmedReservation
    ) {
      return t("paymentComplete.titles.errorReservation");
    }

    if (
      paymentStatus !== PaymentStatus.Reserved &&
      paymentStatus !== PaymentStatus.Confirmed
    ) {
      return t("paymentComplete.titles.errorPayment");
    }

    return t("paymentComplete.titles.success");
  }, [t, paymentStatus, reservationStatus]);

  const subTitle = useMemo(() => {
    if (
      paymentStatus === PaymentStatus.Confirmed ||
      paymentStatus === PaymentStatus.Reserved
    ) {
      switch (reservationStatus) {
        case ReservationStatus.PendingConfirmation:
          return t("paymentComplete.subTitles.warning");

        case ReservationStatus.ConfirmedReservation:
          return t("paymentComplete.subTitles.success");

        default:
          return null;
      }
    }

    return null;
  }, [t, paymentStatus, reservationStatus]);

  const showBookingAction = useMemo(() => {
    return (
      !isLoading && reservationStatus === ReservationStatus.ConfirmedReservation
    );
  }, [isLoading, reservationStatus]);

  if (id === undefined) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <PageWrapper>
      <Container>
        <PagePaper title={t("paymentComplete.pageTitle")}>
          <S.Wrapper>
            {isLoading ? (
              <>
                <Loader />
                <h5>{t("paymentComplete.titles.loading")}</h5>
              </>
            ) : (
              <S.InnerWrapper>
                <S.Title>
                  {iconProps && (
                    <iconProps.Component
                      size={36}
                      style={{ color: iconProps.color }}
                    />
                  )}
                  <span>{title}</span>
                </S.Title>
                {subTitle && <h5>{subTitle}</h5>}
              </S.InnerWrapper>
            )}
            {showBookingAction && (
              <Link to={`/profile/bookings/${reservationId}`}>
                <Button variant="secondary">
                  {t("paymentComplete.actions.viewBooking")}
                </Button>
              </Link>
            )}
          </S.Wrapper>
        </PagePaper>
      </Container>
    </PageWrapper>
  );
};

export default PaymentComplete;

import {
  AccordionSummary,
  AccordionTriggerBase,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./ImportantInfo.styled";

interface ImportantInfoProps {
  remarks?: string[];
  isLoading: boolean;
}

const MAX_REMARKS = 6;

const ImportantInfo = ({ remarks, isLoading }: ImportantInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <S.SectionTitle>
        {t("searchHotelBook.content.importantInfo.title")}
      </S.SectionTitle>
      <AccordionSummary
        head={({ isOpen }) => {
          if (isLoading || !remarks || remarks.length <= MAX_REMARKS) {
            return null;
          }

          return (
            <AccordionTriggerBase
              isOpen={isOpen}
              showText={t("searchHotelBook.content.importantInfo.showMore")}
              hideText={t("searchHotelBook.content.importantInfo.showLess")}
            />
          );
        }}
        summary={
          isLoading ? (
            <Skeleton count={4} />
          ) : (
            <>
              {remarks
                ?.slice(0, MAX_REMARKS)
                .map((remark, remarkIndex) => (
                  <p key={remarkIndex}>{remark}</p>
                ))}
            </>
          )
        }
      >
        {remarks
          ?.slice(MAX_REMARKS)
          .map((remark, remarkIndex) => (
            <p key={remarkIndex + MAX_REMARKS}>{remark}</p>
          ))}
      </AccordionSummary>
    </>
  );
};

export default ImportantInfo;

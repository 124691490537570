import { ActivityDetails } from "@hotelspoint/api";
import {
  Button,
  Dialog,
  MarkupPrice,
  Modal,
  MosaicLayout,
  Price,
} from "@hotelspoint/components";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import ActivityContent from "../../../ActivityContent";
import * as S from "./ActivityDetailsModal.styled";

interface ActivityDetailsModalProps {
  data: ActivityDetails;
  isOpen: boolean;
  isLoading: boolean;
  handleImageClick: (index: number) => void;
  handleSelectActivity: () => void;
  onClose: () => void;
}

const MOSAIC_IMAGES_MAX = 3;

const ActivityDetailsModal = ({
  data,
  isOpen,
  isLoading,
  handleImageClick,
  handleSelectActivity,
  onClose,
}: ActivityDetailsModalProps) => {
  const { t } = useTranslation();

  const { activity } = data || {};

  const images = useMemo(() => {
    if (!activity?.images) return [];

    return activity?.images.slice(0, MOSAIC_IMAGES_MAX).map(img => ({
      image: getImagePathFromCDN(img, ImageType.Activity),
      alt: `Image ${img}`,
    }));
  }, [activity?.images]);

  if (!activity) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <S.ModalWrapper>
          <Modal
            title={t("searchActivitiesResults.activityDetailsModal.title")}
            onClose={onClose}
          >
            <div>
              {activity.images.length !== 0 && (
                <S.MosaicWrapper>
                  <MosaicLayout
                    value={images}
                    height={120}
                    isLoading={isLoading}
                    onClick={handleImageClick}
                  />
                </S.MosaicWrapper>
              )}
              <S.BaseInfoWrapper>
                <S.FlexGrow>
                  <h3>{isLoading ? <Skeleton /> : activity.name}</h3>
                  <S.LocationWrapper>
                    {isLoading ? (
                      <S.FlexGrow>
                        <Skeleton />
                      </S.FlexGrow>
                    ) : (
                      <>
                        <IconMapPinFilled size={16} />
                        <p>{activity.location}</p>
                      </>
                    )}
                  </S.LocationWrapper>
                </S.FlexGrow>
                <S.ActivityPriceWrapper>
                  <MarkupPrice
                    value={{
                      total: activity.price,
                      net: activity.priceNet,
                    }}
                  >
                    <S.Emphasized>
                      {t("searchActivitiesResults.activityDetailsModal.from")}
                    </S.Emphasized>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <S.Price>
                        <Price value={activity.price} />
                      </S.Price>
                    )}
                  </MarkupPrice>
                  <Button
                    variant="tertiary"
                    fullWidth={true}
                    isLoading={isLoading}
                    onClick={handleSelectActivity}
                  >
                    {t(
                      "searchActivitiesResults.activityDetailsModal.actions.select",
                    )}
                  </Button>
                </S.ActivityPriceWrapper>
              </S.BaseInfoWrapper>
              <S.Divider />
              <ActivityContent
                content={activity.content}
                isLoading={isLoading}
              />
            </div>
            <Modal.Actions style={{ justifyContent: "flex-end" }}>
              <Button isLoading={isLoading} onClick={onClose}>
                {t(
                  "searchActivitiesResults.activityDetailsModal.actions.close",
                )}
              </Button>
            </Modal.Actions>
          </Modal>
        </S.ModalWrapper>
      </Dialog.Content>
    </Dialog>
  );
};

export default ActivityDetailsModal;

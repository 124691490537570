import { handleError, useCreateOffer, useUpdateOffer } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  FormAdapter,
  FormChildrenMethods,
  FormContext,
  FormInput,
  FormLayout,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FormValues } from "./ProfileOfferForm.util";

interface ProfileOfferFormProps extends FormChildrenMethods<FormValues> {
  offerId?: number;
  currentOffer: boolean;
}

const ProfileOfferForm = ({
  offerId,
  currentOffer,
  reset,
}: ProfileOfferFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateOffer, isLoadingUpdate] = useUpdateOffer(offerId ?? 0);
  const [createOffer, isLoadingCreate] = useCreateOffer();

  const onSubmit = async (formValues: FormValues) => {
    try {
      if (currentOffer) {
        await createOffer(formValues);
        toast.success(t("toast.profileOffer.createOffer"));
        navigate(`/profile/offers/${offerId}`);
      } else {
        await updateOffer(formValues);
        toast.success(t("toast.profileOffer.updateOffer"));
      }

      reset({}, { keepValues: true });
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  //should we have a check that prevents the creation of an offer without rates

  return (
    <>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="name" label={t("profileOffer.form.name.label")}>
              {props => (
                <FormInput
                  {...props}
                  placeholder={t("profileOffer.form.name.placeholder")}
                  disabled={currentOffer ? isLoadingCreate : isLoadingUpdate}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="client"
              label={t("profileOffer.form.client.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  placeholder={t("profileOffer.form.client.placeholder")}
                  disabled={currentOffer ? isLoadingCreate : isLoadingUpdate}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>

        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={currentOffer ? isLoadingCreate : isLoadingUpdate}
              onClick={handleSubmit(onSubmit)}
            >
              {currentOffer
                ? t("profileOffer.form.create")
                : t("profileOffer.form.update")}
            </Button>
          )}
        />
      </FormLayout>
    </>
  );
};

export default ProfileOfferForm;

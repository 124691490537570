import { HotelRateResponse } from "@hotelspoint/api";
import {
  PassengerInfo,
  Price,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { HotelRoomRate } from "@hotelspoint/types";
import { IconUserFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import * as S from "../SearchHotelBookForm/SearchHotelBookForm.styled";

interface LeaderOnlyInfoProps {
  hotelRate?: HotelRateResponse;
}

const RoomDetails = ({
  room,
  roomIndex,
}: {
  room: HotelRoomRate;
  roomIndex: number;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <S.FlexWrapper>
        <S.RoomName>{room.roomName}</S.RoomName>
        <S.Price>
          <Price value={room.price} />
        </S.Price>
      </S.FlexWrapper>
      <S.SectionTitle>
        <IconUserFilled size={18} />
        <span>{t("searchHotelBook.content.adult", { count: 1 })}</span>
      </S.SectionTitle>
      <PassengerInfo passengerIndex={0} name={`leader.${roomIndex}.0`} />
      {room.promotions && (
        <S.StatusBlockWrapper>
          <StatusBlock status={StatusFlavour.Info}>
            <S.StatusBlockHeader>
              {t("searchHotelBook.content.promoOffer")}
            </S.StatusBlockHeader>
            {Array.isArray(room.promotions) &&
              room.promotions.map(promotion => (
                <S.StatusBlockCopy key={promotion.name}>
                  {promotion.name}
                  {promotion.amount && (
                    <>
                      : <Price value={promotion.amount} />
                    </>
                  )}
                </S.StatusBlockCopy>
              ))}
          </StatusBlock>
        </S.StatusBlockWrapper>
      )}
    </div>
  );
};

const LeaderOnlyInfo = ({ hotelRate }: LeaderOnlyInfoProps) => {
  let roomIndex = 0;

  return (
    <>
      {hotelRate?.rooms.map(room => {
        if (room.numRooms === 1) {
          const element = (
            <RoomDetails key={roomIndex} room={room} roomIndex={roomIndex} />
          );
          roomIndex += 1;
          return element;
        } else {
          return Array.from({ length: room.numRooms }).map(
            (_, numRoomIndex) => {
              const element = (
                <RoomDetails
                  key={`${roomIndex}-${numRoomIndex}`}
                  room={room}
                  roomIndex={roomIndex}
                />
              );
              roomIndex += 1;
              return element;
            },
          );
        }
      })}
    </>
  );
};

export default LeaderOnlyInfo;

import { Transfer } from "@hotelspoint/api";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import * as S from "./TransferContent.styled";

interface TransferContentProps {
  transfer?: Transfer;
  isLoading?: boolean;
}

const TransferContent = ({ transfer }: TransferContentProps) => {
  const { t } = useTranslation();
  return (
    <>
      <S.Transfer>
        {t("searchTransfersResults.detailsModal.direction.outboundTransfer")}
      </S.Transfer>
      {transfer?.outboundTransfer.remarks.map((remark, index) => (
        <div key={index}>
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(remark),
            }}
          />
        </div>
      ))}
      <S.Divider />
      <S.Transfer>
        {t("searchTransfersResults.detailsModal.direction.returnTransfer")}
      </S.Transfer>
      {transfer?.returnTransfer.remarks.map((remark, index) => (
        <div key={index}>
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(remark),
            }}
          />
        </div>
      ))}
    </>
  );
};

export default TransferContent;

import { spacing, timing } from "@hotelspoint/theme";
import styled from "styled-components";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.75)}px;
  padding: ${spacing(2)}px;
  transition: box-shadow ${timing.normal}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 100%;

  &:hover {
    box-shadow:
      0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;

  svg {
    min-width: 20px;
  }
`;

export const Title = styled.h6`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

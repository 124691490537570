import { useHotelCountByPlace, usePlaces } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerRange,
  FormLayout,
  FormRoomSelector,
  FormSelect,
  OptionGroup,
  PlaceTypeIcon,
  TextItalic,
} from "@hotelspoint/components";
import { countryGroups } from "@hotelspoint/constants";
import {
  useRecentHotelSearchesStore,
  useUserAgencyStore,
} from "@hotelspoint/store";
import { PlaceType } from "@hotelspoint/types";
import { addDays, getPlaceTypeName } from "@hotelspoint/utils";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import qs from "query-string";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { PLACE_TYPE_ORDER, PLACE_TYPES } from "../constants";
import { SearchHotelsViewState } from "../types";
import useHotelSearchParams from "../useHotelSearchParams";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchHotelsForm.form";
import * as S from "./SearchHotelsForm.styled";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const SearchHotelsFormInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const navigate = useNavigate();

  const [, setQuery] = useHotelSearchParams();

  const [search, place, dates] = useWatch({
    name: ["search", "place", "dates"],
  });
  const [debouncedSearch] = useDebounce(search, 350);

  const [hotelsCount, isLoadingHotelsCount] = useHotelCountByPlace({
    placeId: place?.id,
    placeType: place?.type !== PlaceType.Hotel ? place?.type : undefined,
  });

  const [places, isLoadingPlaces] = usePlaces({
    types: PLACE_TYPES,
    search: debouncedSearch ?? "",
  });

  const recentSearchesStore = useRecentHotelSearchesStore(state => state);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const placeOptionsFlat = useMemo(() => {
    return flatten(placeGroupOptions.map(group => group.options));
  }, [placeGroupOptions]);

  const displayCounter = useMemo(() => {
    return place?.id && place?.type !== PlaceType.Hotel;
  }, [place]);

  const disabledDates = useMemo(() => {
    if (dates?.from && !dates?.to) {
      return addDays(dates.from, 1);
    }

    return dateToday;
  }, [dateToday, dates?.from, dates?.to]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const query = {
        ...payload,
        view: SearchHotelsViewState.List,
      };

      // Find the place option and set the name inside the meta
      const placeOption = placeOptionsFlat.find(
        p => p?.value === payload.placeId,
      );

      // Populate the recent searches store
      if (placeOption) {
        const queryAttributesToSave = omit(query, ["nationality"]);

        recentSearchesStore.add({
          ...queryAttributesToSave,
          meta: {
            placeName: placeOption?.label as string,
          },
        });
      }

      // If the selected place is of type hotel, navigate to the hotel page
      if (query.placeType === PlaceType.Hotel) {
        const queryParams = omit(query, ["view"]);
        const search = qs.stringify({
          ...queryParams,
          rooms: JSON.stringify(query.rooms),
        });

        navigate({
          pathname: `/search/hotels/${query.placeId}`,
          search,
        });
      } else {
        setQuery(query);
      }
    },
    [placeOptionsFlat, recentSearchesStore, navigate, setQuery],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="search" label={t("searchHotels.search.label")}>
            {props => (
              <FormAutoComplete
                {...props}
                placeholder={t("searchHotels.search.placeholder")}
                options={placeGroupOptions}
                isLoading={isLoadingPlaces}
                renderOption={option => (
                  <S.PlaceOption key={option.value}>
                    <PlaceTypeIcon
                      type={option.meta.type}
                      iconProps={{ size: 22 }}
                    />
                    <span>{option.label}</span>
                  </S.PlaceOption>
                )}
                onSelect={handleSelectPlace}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="dates" label={t("searchHotels.dates.label")}>
            {props => (
              <FormDatePickerRange
                {...props}
                placeholder={t("searchHotels.dates.placeholder")}
                dayPickerProps={{
                  disabled: [{ before: disabledDates }],
                  defaultMonth: new Date(currentYear, dateToday.getMonth()),
                  fromYear: currentYear,
                }}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="rooms" label={t("searchHotels.rooms.label")}>
            {props => (
              <FormRoomSelector
                {...props}
                placeholder={t("searchHotels.rooms.placeholder")}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="nationality"
            label={t("searchHotels.nationality.label")}
          >
            {props => (
              <FormSelect
                {...props}
                placeholder={t("searchHotels.nationality.placeholder")}
                options={countryOptions}
                isClearable={false}
              />
            )}
          </FormAdapter>
        </Box>
        <Box
          width={[1, 1, 1 / 2, 1 / 2]}
          px={[0, 0, 1, 1]}
          py={1}
          as={S.ActionWrapper}
        >
          {displayCounter && (
            <TextItalic>
              {isLoadingHotelsCount ? (
                <Skeleton height={10} />
              ) : (
                t("searchHotels.count", {
                  count: hotelsCount,
                })
              )}
            </TextItalic>
          )}
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchHotels.submit")}
              </Button>
            )}
          />
        </Box>
      </Flex>
    </FormLayout>
  );
};

interface SearchHotelsFormProps {
  initialValues?: Partial<FormValues>;
}

const SearchHotelsForm = ({ initialValues = {} }: SearchHotelsFormProps) => {
  const agency = useUserAgencyStore(state => state.agency);

  // Pleload the agency country code under the `nationality` form value
  // Load initial values from the outside - outdated recent search queries without dates
  const formValues = useMemo(
    () => ({
      ...defaultValues,
      ...initialValues,
      nationality: `${agency?.countryCode}`.toLowerCase(),
    }),
    [initialValues, agency],
  );

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <SearchHotelsFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchHotelsForm;

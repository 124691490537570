import { Transfer } from "@hotelspoint/api";
import { Button, Dialog, Modal } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import TransferContent from "../../TransferContent";
import * as S from "./TransferDetailsModal.styled";

interface TransferDetailsModalProps {
  transfer?: Transfer;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
}

const TransferDetailsModal = ({
  transfer,
  isOpen,
  isLoading,
  onClose,
}: TransferDetailsModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        {!isLoading && (
          <S.ModalWrapper>
            <Modal
              title={t("searchTransfersResults.detailsModal.title")}
              onClose={onClose}
            >
              <div>
                <TransferContent transfer={transfer} />
              </div>
              <S.Divider />
              <Modal.Actions style={{ justifyContent: "flex-end" }}>
                <Button isLoading={isLoading} onClick={onClose}>
                  {t("searchTransfersResults.detailsModal.actions.close")}
                </Button>
              </Modal.Actions>
            </Modal>
          </S.ModalWrapper>
        )}
      </Dialog.Content>
    </Dialog>
  );
};

export default TransferDetailsModal;

import {
  ActivityOption as ActivityOptionAPI,
  ActivityOptionsResponse,
} from "@hotelspoint/api";
import {
  ActivityOption,
  ActivityOptionPrice,
  Box,
  Button,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormSelect,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { mediaQuery } from "@hotelspoint/theme";
import { useMediaQuery } from "@hotelspoint/utils";
import qs from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import useActivitySearchParams from "../../useActivitySearchParams";
import ActivityPriceDetails from "../ActivityPriceDetails";
import * as S from "./ActivityTicketsSection.styled";
import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./ActivityTicketsSection.util";

interface ActivityTicketsSectionParams extends Record<string, string> {
  id: string;
}

interface ActivityTicketsSectionProps {
  options: ActivityOptionsResponse;
  selectedDate: string;
}

interface ActivityTicketsSectionInnerProps
  extends FormChildrenMethods<FormValues>,
    ActivityTicketsSectionProps {}

interface FilterOptions {
  code: string;
  name: string;
}

const mapFilters = (filters: FilterOptions[]) => {
  if (!filters) {
    return [];
  }
  return filters.map(filter => ({
    label: filter.name,
    value: filter.code,
  }));
};

const filterOptions = (
  options: ActivityOptionAPI[],
  language: string | null,
  time: string | null,
) => {
  return options
    .filter(
      option =>
        (!language || option.languages.includes(language)) &&
        (!time || option.sessions.includes(time)),
    )
    .map(option => ({
      ...option,
      prices: option.prices.filter(
        price =>
          (!language || price.languages.includes(language)) &&
          (!time || price.sessions.includes(time)),
      ),
    }))
    .filter(option => option.prices.length > 0);
};

const ActivityTicketsSectionInner = ({
  options,
  selectedDate,
  setValue,
}: ActivityTicketsSectionInnerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query] = useActivitySearchParams();

  const { id } = useParams<ActivityTicketsSectionParams>();
  const activityId = Number(id);

  const isMobileL = useMediaQuery(mediaQuery.mobileL);

  const [sessions, languages] = useWatch({ name: ["sessions", "languages"] });

  const mappedSessions = useMemo(() => {
    return mapFilters(options.sessions);
  }, [options.sessions]);

  const mappedLanguages = useMemo(() => {
    return mapFilters(options.languages);
  }, [options.languages]);

  const [selectedOption, setSelectedOption] =
    useState<ActivityOptionAPI | null>(null);

  const filteredOptions = useMemo(() => {
    const data = filterOptions(options.options, languages, sessions);

    if (data.length === 1) {
      setSelectedOption(data[0]);
    } else {
      setSelectedOption(null);
    }
    return data;
  }, [options.options, sessions, languages]);

  const updateNumTickets = (priceKey: number, newNumTickets: number) => {
    setSelectedOption(prevValue => {
      if (prevValue === null) {
        return null;
      }
      return {
        ...prevValue,
        prices: prevValue.prices.map(price =>
          price.key === priceKey
            ? { ...price, numTickets: newNumTickets }
            : price,
        ),
      };
    });
  };

  const selectedTickets = useMemo(
    () =>
      selectedOption?.prices
        .filter(({ numTickets }) => numTickets !== undefined)
        .map(({ key, numTickets }) => `${key}:${numTickets}`)
        .join(","),
    [selectedOption],
  );

  const isStandaloneSelected = useMemo(() => {
    return selectedOption?.prices.filter(
      price => price.standalone && price.numTickets,
    );
  }, [selectedOption?.prices]);

  const handleBook = () => {
    const search = qs.stringify({
      searchId: query.searchId as number,
      session: sessions,
      languages: languages,
      date: selectedDate,
      option: selectedOption?.key,
      tickets: selectedTickets,
    });
    navigate({
      pathname: `/search/activities/${activityId}/book`,
      search,
    });
  };

  useEffect(() => {
    if (mappedLanguages.length > 0 && !languages) {
      setValue("languages", mappedLanguages[0].value);
    }
    if (mappedSessions.length > 0 && !sessions) {
      setValue("sessions", mappedSessions[0].value);
    }
  }, [setValue, mappedLanguages, languages, mappedSessions, sessions]);

  return (
    <>
      {(mappedSessions.length !== 0 || mappedLanguages.length !== 0) && (
        <S.FilterWrapper>
          {mappedSessions.length !== 0 && (
            <Box width={[1, 1, 1 / 2, 1 / 4]}>
              <FormAdapter
                name="sessions"
                label={t("searchActivityResult.filters.sessions")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={mappedSessions}
                    isSearchable={false}
                  />
                )}
              </FormAdapter>
            </Box>
          )}
          {mappedLanguages.length !== 0 && (
            <Box width={[1, 1, 1 / 2, 1 / 4]}>
              <FormAdapter
                name="languages"
                label={t("searchActivityResult.filters.languages")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={mappedLanguages}
                    isSearchable={false}
                  />
                )}
              </FormAdapter>
            </Box>
          )}
        </S.FilterWrapper>
      )}
      {filteredOptions.length === 0 ? (
        <StatusBlock status={StatusFlavour.Warning}>
          <h6>{t(`searchActivityResult.warning.title`)}</h6>
          <p>{t(`searchActivityResult.warning.description`)}</p>
        </StatusBlock>
      ) : (
        filteredOptions[0].name && (
          <>
            <S.Headline>{t("searchActivityResult.selectOption")}</S.Headline>
            <S.OptionsWrapper>
              {filteredOptions.length === 1 ? (
                <ActivityOption
                  name={filteredOptions[0].name}
                  value={true}
                  isDisabled={true}
                />
              ) : (
                filteredOptions
                  .filter(
                    option =>
                      selectedOption === null ||
                      selectedOption?.key === option.key,
                  )
                  .map(option => (
                    <ActivityOption
                      key={option.key}
                      name={option.name}
                      value={selectedOption?.key === option.key}
                      onChange={() =>
                        setSelectedOption(
                          (prevValue: ActivityOptionAPI | null) =>
                            prevValue === null ? option : null,
                        )
                      }
                    />
                  ))
              )}
            </S.OptionsWrapper>
          </>
        )
      )}
      {selectedOption && filteredOptions.length !== 0 && (
        <>
          <S.Headline>{t("searchActivityResult.numberOfTickets")}</S.Headline>
          <S.OptionsWrapper>
            {selectedOption.prices?.map(price => (
              <ActivityOptionPrice
                key={price.key}
                name={price.name}
                price={price.priceNet}
                value={price.numTickets}
                isDisabled={
                  selectedOption.isPredefined ||
                  (!price.standalone && isStandaloneSelected?.length === 0)
                }
                isStandalone={price.standalone}
                onChange={num => updateNumTickets(price.key, num)}
              />
            ))}
          </S.OptionsWrapper>
          <S.Footer>
            <ActivityPriceDetails option={selectedOption} />
            <Button
              fullWidth={isMobileL ? false : true}
              variant="tertiary"
              onClick={() => handleBook()}
              isDisabled={isStandaloneSelected?.length === 0}
            >
              {t("searchActivityResult.book")}
            </Button>
          </S.Footer>
        </>
      )}
    </>
  );
};

const ActivityTicketsSection = ({
  options,
  selectedDate,
}: ActivityTicketsSectionProps) => {
  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema}>
      {formMethods => (
        <ActivityTicketsSectionInner
          {...formMethods}
          options={options}
          selectedDate={selectedDate}
        />
      )}
    </Form>
  );
};

export default ActivityTicketsSection;

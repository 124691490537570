import { ActivityService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useActivityDetails = (searchId: number, id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["activity", searchId, id],
    queryFn: () => ActivityService.getDetails(searchId, id),
    enabled: !!searchId && !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useActivityDetails;

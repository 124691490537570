import { Paper } from "@hotelspoint/components";
import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled(Paper)`
  padding: ${spacing(1)}px;
`;

export const MosaicWrapper = styled.div`
  margin-bottom: ${spacing(2)}px;
`;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

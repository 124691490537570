import { TransferSearchPayload, TransferService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useTransferAvailabilitySearch = (params: TransferSearchPayload) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["transfers_availability", params],
    queryFn: () => TransferService.search(params),
  });

  const queryInstance = {
    refetch,
  };

  const ret: [typeof data, boolean, typeof queryInstance] = [
    data,
    isLoading,
    queryInstance,
  ];
  return ret;
};

export default useTransferAvailabilitySearch;

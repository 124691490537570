import { useActivityDetails, useActivityOptions } from "@hotelspoint/api";
import {
  Button,
  CalendarSelector,
  Dialog,
  Modal,
} from "@hotelspoint/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ActivityTicketsSection from "../../components/ActivityTicketsSection";
import useActivitySearchParams from "../../useActivitySearchParams";
import * as S from "./ActivityOptionsModal.styled";

interface ActivityOptionsModalProps {
  isOpen: boolean;
  activityId: number;
  searchId: number;
  onClose: () => void;
}

const ActivityOptionsModal = ({
  isOpen,
  activityId,
  searchId,
  onClose,
}: ActivityOptionsModalProps) => {
  const { t } = useTranslation();

  const [query] = useActivitySearchParams();

  const [activityDetails] = useActivityDetails(searchId as number, activityId);

  const [selectedDate, setSelectedDate] = useState<string>(
    query.dateIn as string,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId,
    id: activityId,
    date: selectedDate,
  });

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <S.ModalWrapper>
          <Modal title={activityDetails?.activity.name} onClose={onClose}>
            <CalendarSelector
              value={selectedDate}
              onChange={setSelectedDate}
              range={{
                from: new Date(query.dateIn as string),
                to: new Date(query.dateOut as string),
              }}
              calendar={activityOptions?.calendar as Record<string, number>}
              isLoading={isLoadingActivityOptions}
            />
            {activityOptions && (
              <>
                <S.Divider />
                <ActivityTicketsSection
                  options={activityOptions}
                  selectedDate={selectedDate}
                />
              </>
            )}
            <S.Divider />
            <Modal.Actions style={{ justifyContent: "flex-end" }}>
              <Button isLoading={isLoadingActivityOptions} onClick={onClose}>
                {t(
                  "searchActivitiesResults.activityOptionsModal.actions.close",
                )}
              </Button>
            </Modal.Actions>
          </Modal>
        </S.ModalWrapper>
      </Dialog.Content>
    </Dialog>
  );
};

export default ActivityOptionsModal;

import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import Price from "../../misc/Price";
import MarkupPrice from "../MarkupPrice";
import * as S from "./ActivityListing.styled";
import thumbPlaceholder from "./assets/thumb-placeholder.jpg";

interface ActivityListingProps {
  id: number;
  searchId: number;
  name: string;
  location: string;
  summary: string;
  provider: {
    brand: string;
    logo: string;
  };
  thumbnail: string;
  categories: string[];
  price: number;
  priceNet: number;
  handleShowInformation: () => void;
  handleThumbnailClick?: () => void;
  handleShowActivity: () => void;
}

const ActivityListing = ({
  id,
  searchId,
  name,
  location,
  summary,
  provider,
  thumbnail,
  categories,
  price,
  priceNet,
  handleShowInformation,
  handleThumbnailClick,
  handleShowActivity,
}: ActivityListingProps) => {
  const { t } = useTranslation();
  const { search: locationSearch } = useLocation();

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("useCache");
    params.set("searchId", `${searchId}`);

    return params.toString();
  }, [locationSearch, searchId]);

  return (
    <S.Wrapper $elevation={3}>
      <S.ThumbnailWrapper>
        <S.Thumbnail
          onClick={handleThumbnailClick}
          $src={
            thumbnail
              ? getImagePathFromCDN(thumbnail as string, ImageType.Activity)
              : thumbPlaceholder
          }
        />
      </S.ThumbnailWrapper>
      <S.Body>
        <S.BodyHeader>
          <div>
            <S.TitleLink
              to={{
                pathname: `/search/activities/${id}`,
                search,
              }}
            >
              <h5>{name}</h5>
            </S.TitleLink>
            <S.Location>
              <IconMapPinFilled size={16} />
              <span>{location}</span>
            </S.Location>
          </div>
        </S.BodyHeader>
        <S.Summary>{summary}</S.Summary>
        <S.BodyFooter>
          <Button variant="outlined" onClick={handleShowInformation}>
            {t("components.activityListing.information")}
          </Button>
          {provider.logo && <S.Provider $src={provider.logo} />}
        </S.BodyFooter>
      </S.Body>
      <S.Aside>
        <S.AsideHeader>{categories.join(", ")}</S.AsideHeader>
        <S.AsideGroup>
          <MarkupPrice
            value={{
              total: price,
              net: priceNet,
            }}
          >
            <S.Emphasized>
              {t("components.activityListing.price.from")}
            </S.Emphasized>
            <S.PriceWrapper>
              <Price value={priceNet} />
            </S.PriceWrapper>
          </MarkupPrice>
          <Button
            variant="tertiary"
            fullWidth={true}
            onClick={handleShowActivity}
          >
            {t("components.activityListing.select")}
          </Button>
        </S.AsideGroup>
      </S.Aside>
    </S.Wrapper>
  );
};

export default ActivityListing;

import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

import { baseStyles } from "../FormInput/FormInput.styled";

export const Textarea = styled.textarea<{
  $hasError: boolean;
}>`
  ${baseStyles};

  resize: vertical;
  padding: ${spacing(1.25)}px;
`;

import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Headline = styled.h6`
  margin-bottom: ${spacing(1)}px;
  font-weight: 600;
`;

export const FilterWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(3)}px;
  margin-bottom: ${spacing(3)}px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(3)}px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  @media ${mediaQuery.mobileL} {
    flex-direction: row;
    row-gap: 0;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 500px;
  row-gap: ${spacing(3)}px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing(1)}px;
`;

export const Title = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

import { PlaceType } from "./place";

export enum SearchActivitiesResultsModalType {
  Details,
  Book,
  Gallery,
}

export enum ActivityContentMark {
  Included = "included",
  Excluded = "excluded",
}

export interface SearchActivitiesResultsModalState {
  id?: number;
  type: SearchActivitiesResultsModalType;
}

export interface ActivitySearchQuery {
  placeId: number;
  placeType: PlaceType;
  dateIn: string;
  dateOut: string;
  adults: number;
  children: number[];
}

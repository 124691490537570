import {
  ActivitySearchPayload,
  useActivityAvailabilitySearch,
  useActivityDetails,
  useActivityOptions,
  usePlace,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  CalendarSelector,
  Container,
  GalleryOverlay,
  MosaicLayout,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import {
  getImagePathFromCDN,
  ImageType,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { IconMapPinFilled, IconMapStar } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ActivityContent from "../ActivityContent";
import ActivityTicketsSection from "../components/ActivityTicketsSection";
import SearchActivitiesForm from "../SearchActivitiesResults/SearchActivitiesForm";
import useActivitySearchParams from "../useActivitySearchParams";
import * as S from "./SearchActivityPage.styled";

const MOSAIC_IMAGES_MAX = 3;

interface SearchActivityPageParams extends Record<string, string> {
  id: string;
}

const SearchActivityPage = () => {
  const { t } = useTranslation();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  useDocumentTitle(t("searchActivityPage.pageTitle"));

  const { id } = useParams<SearchActivityPageParams>();
  const activityId = Number(id);
  const [query] = useActivitySearchParams();

  // @todo: why are we using state here?
  const [selectedDate, setSelectedDate] = useState<string>(
    query.dateIn as string,
  );

  const [, , { refetch }] = useActivityAvailabilitySearch(
    query as ActivitySearchPayload,
  );

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    query.searchId as number,
    activityId,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: query.searchId as number,
    id: activityId,
    date: selectedDate,
  });

  const { activity } = activityDetails || {};

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const images = useMemo(() => {
    if (!activity?.images) return [];

    return activity?.images.slice(0, MOSAIC_IMAGES_MAX).map(img => ({
      image: getImagePathFromCDN(img, ImageType.Activity),
      alt: `Image ${img}`,
    }));
  }, [activity?.images]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchActivities.pageTitle"),
        href: "/search/activities",
        icon: () => <IconMapStar size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
      {
        title: activityDetails?.activity.name as string,
        icon: () => <IconMapStar size={18} />,
      },
    ],
    [t, place, activityDetails],
  );

  const handleMosaicClick = (index: number) => {
    setIsGalleryOpen(true);
    setInitialGalleryIndex(index);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={isGalleryOpen}
        images={activity?.images ?? []}
        imageType={ImageType.Activity}
        initialSlideIndex={initialGalleryIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
      <PageWrapper>
        <Container>
          <PageSpacer>
            <BreadcrumbContainer>
              {isLoadingPlace ? (
                <Skeleton width={120} height={10} />
              ) : (
                <Breadcrumb items={breadcrumbs} />
              )}
            </BreadcrumbContainer>
            <PageSplitLayout>
              <PageSplitLayout.Sidebar>
                <S.Wrapper>
                  <SearchActivitiesForm refetch={refetch} />
                </S.Wrapper>
              </PageSplitLayout.Sidebar>
              <PageSplitLayout.Content>
                <S.Wrapper>
                  {activity?.images.length !== 0 && (
                    <S.MosaicWrapper>
                      <MosaicLayout
                        value={images}
                        height={120}
                        isLoading={isLoadingActivityDetails}
                        onClick={handleMosaicClick}
                      />
                    </S.MosaicWrapper>
                  )}
                  <h3>
                    {isLoadingActivityDetails ? <Skeleton /> : activity?.name}
                  </h3>
                  <S.LocationWrapper>
                    {isLoadingActivityDetails ? (
                      <S.FlexGrow>
                        <Skeleton />
                      </S.FlexGrow>
                    ) : (
                      <>
                        <IconMapPinFilled size={16} />
                        <p>{activity?.location}</p>
                      </>
                    )}
                  </S.LocationWrapper>
                  <S.Divider />
                  <ActivityContent
                    isLoading={isLoadingActivityDetails}
                    content={activity?.content}
                  />
                  <CalendarSelector
                    value={selectedDate}
                    onChange={setSelectedDate}
                    range={{
                      from: new Date(query.dateIn as string),
                      to: new Date(query.dateOut as string),
                    }}
                    calendar={
                      activityOptions?.calendar as Record<string, number>
                    }
                    isLoading={isLoadingActivityOptions}
                  />
                  <S.Divider />
                  {activityOptions && (
                    <ActivityTicketsSection
                      options={activityOptions}
                      selectedDate={selectedDate}
                    />
                  )}
                </S.Wrapper>
              </PageSplitLayout.Content>
            </PageSplitLayout>
          </PageSpacer>
        </Container>
      </PageWrapper>
    </>
  );
};

export default SearchActivityPage;

import { TransferType } from "@hotelspoint/api";
import { TransferTripType } from "@hotelspoint/types";

export const getTransferTripTypeName = (type: TransferTripType) => {
  switch (type) {
    case TransferTripType.OneWay:
      return "transferTripType.oneWay";

    case TransferTripType.RoundTrip:
      return "transferTripType.roundTrip";

    case TransferTripType.Airports:
      return "transferTripType.airports";

    default:
      return "Unknown transfer trip type";
  }
};

export const getTransferTypeName = (type: TransferType) => {
  switch (type) {
    case TransferType.Private:
      return "transferType.private";

    case TransferType.Shared:
      return "transferType.shared";

    default:
      return "Unknown transfer type";
  }
};

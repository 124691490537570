export const FORMAT_DATE_ISO = "yyyy-MM-dd";
export const FORMAT_DATE = "dd.MM.yyyy";
export const FORMAT_DATE_READABLE = "dd LLL, yyyy";
export const FORMAT_DATE_READABLE_FULL = "d LLLL yyyy";

export const FORMAT_DATE_TIME_ISO = `${FORMAT_DATE_ISO}'T'HH:mm:ss'Z'`;
export const FORMAT_DATE_TIME = `${FORMAT_DATE} HH:mm`;
export const FORMAT_DATE_DAY_MONTH_SHORT = "dd MMM";
export const FORMAT_TIME = "HH:mm";
export const FORMAT_DATE_TIME_HYPHEN = `${FORMAT_DATE} - HH:mm`;

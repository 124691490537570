import { useTranslation } from "react-i18next";

import * as S from "./SearchActivityBookContent.styled";
import SearchActivityBookForm from "./SearchActivityBookForm";

const SearchActivityBookContent = () => {
  const { t } = useTranslation();

  return (
    <S.Card>
      <S.Title>{t("searchActivityBook.content.passengers")}</S.Title>
      <SearchActivityBookForm />
    </S.Card>
  );
};

export default SearchActivityBookContent;

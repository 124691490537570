import { USD_BGN_RATE } from "@hotelspoint/constants";
import { InferType, object, string } from "yup";

export const validationSchema = object({
  from: string(),
  to: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  from: `${USD_BGN_RATE}`,
  to: `0`,
};
